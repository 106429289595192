const companies = {
  companyName: { type: "String" },
  companyContactName: { type: "String" },
  companyContactDepartment: { type: "String" },
  companyContactTel: { type: "String" },
  companyContactMails: { type: "Array" },
  automaticApprovalMails: { type: "Array" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
  managerAccountEnabled: { type: "Boolean" },
  companyDescription: { type: "String" },
}

const labels = {
  labelName: { type: "String" },
  vguardMaxCount: { type: "Number" },
  vguardCount: { type: "Number" },
  createdAt: { type: "Timestamp" },
}

const approvalRequests = {
  role: { type: "String" },
  email: { type: "String" },
  displayName: { type: "String" },
  createdAt: { type: "Timestamp" },
}

const toolManagers = {
  displayName: { type: "String" },
  createdAt: { type: "Timestamp" }
}

const toolUsers = {
  displayName: { type: "String" },
  createdAt: { type: "Timestamp" }
}

const vguards = {
  displayName: { type: "String" },
  using: { type: "Boolean" },
  settings: {
    type: "Map",
    map: {
      idlingEyesEnabled: { type: "Boolean" },
      idlingEyesSpeed:  { type: "Float" },
      idlingFaceEnabled:  { type: "Boolean" },
      idlingFaceSpeed:  { type: "Float" },
      idlingFaceRange:  { type: "Number" },
      childTallEnabled:  { type: "Boolean" },
      childTallHeight:  { type: "Float" },
      cameraSettings: {
        type: "Map",
        map: {
          camera1IP: { type: "String" },
          camera1ID: { type: "String" },
          camera1Password: { type: "String" },
          camera1QueryString: { type: "String", canChange: true },
          camera2IP: { type: "String" },
          camera2ID: { type: "String" },
          camera2Password: { type: "String" },
          camera2QueryString: { type: "String", canChange: true },
          camera3IP: { type: "String" },
          camera3ID: { type: "String" },
          camera3Password: { type: "String" },
          camera3QueryString: { type: "String", canChange: true },
        }
      },
      scheduleEnabled: { type: "Boolean", canChange: true, default: false },
      characterRef: { type: "Reference" },
      nearZone: {
        type: "Map",
        map: {
          distance: { type: "Float" },
          waitTime: { type: "Number" },
          captureEnabled: { type: "Boolean" },
          greetingEnabled: { type: "Boolean" },
        }
      },
      midZone: {
        type: "Map",
        map: {
          virtualwall01X1: { type: "Float" },
          virtualwall01Z1: { type: "Float" },
          virtualwall01X2: { type: "Float" },
          virtualwall01Z2: { type: "Float" },
          virtualwall02X1: { type: "Float" },
          virtualwall02Z1: { type: "Float" },
          virtualwall02X2: { type: "Float" },
          virtualwall02Z2: { type: "Float" },
          virtualwall03X1: { type: "Float" },
          virtualwall03Z1: { type: "Float" },
          virtualwall03X2: { type: "Float" },
          virtualwall03Z2: { type: "Float" },
          speed: { type: "Float" },
          rerunWaitTime: { type: "Number" },
          captureEnabled: { type: "Boolean" },
          greetingEnabled: { type: "Boolean" },
        }
      },
      srs: {
        type: "Map",
        map: {
          bargeIn: { type: "Boolean" },
          silentDuration: { type: "Number" },
          rejectDuration: { type: "Number" },
          retryOverTimes: { type: "Number" },
        }
      },
      faceDetection: {
        type: "Map",
        canChange: true,
        map: {
          faceDetectionEnabled: { type: "Boolean", canChange: true, default: true, },
          faceDetectionServerIP: { type: "String", canChange: true, },
        }
      },
      speechDetection	: {
        type: "Map",
        canChange: true,
        map: {
          noddingMotionEnabled: { type: "Boolean", canChange: true, default: true, },
          textDisplayEnabled: { type: "Boolean", canChange: true, default: false, },
          srsResultDisplayEnabled: { type: "Boolean", canChange: true, default: false, },
        }
      },
      callTimeoutSec: { type: "Number", canChange: true, },
    }
  },
  createdAt: { type: "Timestamp", canChange: true, },
}

const schedules = {
  repeatFrag: { type: "Boolean" },
  dayOfTheWeek: { type: "Array" },
  year: { type: "Number" },
  month: { type: "Number" },
  day: { type: "Number" },
  hour: { type: "Number" },
  minute: { type: "Number" },
  time: { type: "Number" },
  createdAt: { type: "Timestamp" },
}

const guidePictures = {
  title: { type: "String" },
  tag: { type: "String", canChange: true, default: "" },
  pictureMd5: { type: "String" },
  picturePath: { type: "String" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const phrases = {
  phraseTitle: { type: "String" },
  tag: { type: "String", canChange: true, default: "" },
  interlanguage: { type: "String", canChange: true },
  phraseLanguage: { type: "String" },
  phraseMd5: { type: "String" },
  modelRef: { type: "Reference" },
  phrasePath: { type: "String" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const faceDetectionPhrases  = {
  phraseType: { type: "String" },
  phraseTitle: { type: "String" },
  tag: { type: "String", canChange: true, default: "" },
  interlanguage: { type: "String" },
  phraseLanguage: { type: "String" },
  phraseMd5: { type: "String" },
  modelRef: { type: "Reference" },
  phrasePath: { type: "String" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const motions = {
  title: { type: "String" },
  modelRef: { type: "Reference" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const motions_default_data = {
  includeCreatedAt: true,
  includeUpdatedAt: true,
  data: function(){
    const result = [];
    const base_motions = [
      { id: '101', title: '立哨（維持）' },
      { id: '102', title: '気を付け（維持）' },
      { id: '103', title: '頷く（頷く→気を付け）' },
      { id: '104', title: '右手を差し出す（維持）' },
      { id: '105', title: '左手を差し出す（維持）' },
      { id: '106', title: '敬礼（維持）' },
      { id: '107', title: 'しゃがみ（維持）' },
      { id: '108', title: '制止（維持）' },
      { id: '109', title: '呼び出し（維持）' },
      { id: '110', title: '地図表示（維持）' },
      { id: '111', title: '敬礼（敬礼→気を付け）' },
      { id: '1000', title: "緊急地震速報"}
    ]
    for(let model_id of models_default_data.data().map(x => x.id)){
      result.push(...base_motions.map(x => {
        return {
          id: model_id + "_" + x.id,
          title: x.title,
          modelRef: { model_id: model_id },
        }
      }))
    }
    return result;
  }
}

const models = {
  title: { type: "String" },
  ttsModelId: { type: "String" },
  ttsSpeakerId: { type: "String" },
  vcModelId: { type: "String", canChange: true },
  displayId: { type: "String" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const models_default_data = {
  includeCreatedAt: true,
  includeUpdatedAt: true,
  data: function(){
    return [
      { id: "1", title: "mamoru_001", ttsModelId: "all_adapt",  ttsSpeakerId: "mamoru", vcModelId: "11", displayId: "displayId" },
      { id: "2", title: "ai_001", ttsModelId: "all_adapt",  ttsSpeakerId: "ai", vcModelId: "10", displayId: "displayId" },
      { id: "3", title: "mamoru_001_mask", ttsModelId: "all_adapt",  ttsSpeakerId: "mamoru", vcModelId: "11", displayId: "displayId" },
      { id: "4", title: "ai_001_mask", ttsModelId: "all_adapt",  ttsSpeakerId: "ai", vcModelId: "10", displayId: "displayId" },
    ]
  }
}

const keywords = {
  tokens: {
    type: "Array",
    array: {
      type: "Map",
      map: {
        word: { type: "String" },
        pos: { type: "String" },
        pos1: { type: "String" },
        pos2: { type: "String" },
        pos3: { type: "String", canChange: true, default: "*" },
      }
    }
  },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const keywordGroups = {
  title: { type: "String" },
  keywords: { type: "Array" },
  tag: { type: "String", canChange: true, default: "" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const manualCommandsGroups = {
  title: { type: "String" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const characters = {
  name: { type: "String" },
  modelRef: { type: "Reference" },
  idlingMotionRef: { type: "Reference" },
  stanbyContentRefs: { type: "Array" },
  manualCommandGroupsOrder: { type: "Array", canChange: true, default: [] },
  digitalInSettings: {
    type: "Map",
    canChange: true,
    map: {
      digitalIn00: { type: "Map", canChange: true, map: { title: { type: "String", canChange: true }, condition: { type: "String", canChange: true, replace: { original: ["メーク", "ブレーク"], value: ["make", "break"] } }, externalCommandRef: { type: "Reference", canChange: true, replace: { original: [""], value: [null] } } } },
      digitalIn01: { type: "Map", canChange: true, map: { title: { type: "String", canChange: true }, condition: { type: "String", canChange: true, replace: { original: ["メーク", "ブレーク"], value: ["make", "break"] } }, externalCommandRef: { type: "Reference", canChange: true, replace: { original: [""], value: [null] } } } },
      digitalIn02: { type: "Map", canChange: true, map: { title: { type: "String", canChange: true }, condition: { type: "String", canChange: true, replace: { original: ["メーク", "ブレーク"], value: ["make", "break"] } }, externalCommandRef: { type: "Reference", canChange: true, replace: { original: [""], value: [null] } } } },
      digitalIn03: { type: "Map", canChange: true, map: { title: { type: "String", canChange: true }, condition: { type: "String", canChange: true, replace: { original: ["メーク", "ブレーク"], value: ["make", "break"] } }, externalCommandRef: { type: "Reference", canChange: true, replace: { original: [""], value: [null] } } } },
      digitalIn04: { type: "Map", canChange: true, map: { title: { type: "String", canChange: true }, condition: { type: "String", canChange: true, replace: { original: ["メーク", "ブレーク"], value: ["make", "break"] } }, externalCommandRef: { type: "Reference", canChange: true, replace: { original: [""], value: [null] } } } },
      digitalIn05: { type: "Map", canChange: true, map: { title: { type: "String", canChange: true }, condition: { type: "String", canChange: true, replace: { original: ["メーク", "ブレーク"], value: ["make", "break"] } }, externalCommandRef: { type: "Reference", canChange: true, replace: { original: [""], value: [null] } } } },
      digitalIn06: { type: "Map", canChange: true, map: { title: { type: "String", canChange: true }, condition: { type: "String", canChange: true, replace: { original: ["メーク", "ブレーク"], value: ["make", "break"] } }, externalCommandRef: { type: "Reference", canChange: true, replace: { original: [""], value: [null] } } } },
      digitalIn07: { type: "Map", canChange: true, map: { title: { type: "String", canChange: true }, condition: { type: "String", canChange: true, replace: { original: ["メーク", "ブレーク"], value: ["make", "break"] } }, externalCommandRef: { type: "Reference", canChange: true, replace: { original: [""], value: [null] } } } },
      digitalIn08: { type: "Map", canChange: true, map: { title: { type: "String", canChange: true }, condition: { type: "String", canChange: true, replace: { original: ["メーク", "ブレーク"], value: ["make", "break"] } }, externalCommandRef: { type: "Reference", canChange: true, replace: { original: [""], value: [null] } } } },
      digitalIn09: { type: "Map", canChange: true, map: { title: { type: "String", canChange: true }, condition: { type: "String", canChange: true, replace: { original: ["メーク", "ブレーク"], value: ["make", "break"] } }, externalCommandRef: { type: "Reference", canChange: true, replace: { original: [""], value: [null] } } } },
    }
  },
}

const nearZoneGreetings = {
  title: { type: "String" },
  phraseRef: { type: "Reference" },
  motionRef: { type: "Reference" },
  playType: { type: "String" },
  timeZones: { type: "Array" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const midZoneGreetings  = {
  title: { type: "String" },
  phraseRef: { type: "Reference" },
  motionRef: { type: "Reference" },
  virtualwallNo: { type: "Number" },
  virtualwallCourse: { type: "String" },
  playType: { type: "String" },
  timeZones: { type: "Array" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const sttPathFilters = {
  type: { type: "String" },
  keywordGroups: { type: "Array" },
  contents: { type: "Array" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const cameraPathFilters = {
  type: { type: "String" },
  contents: { type: "Array" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const callPathFilters = {
  type: { type: "String" },
  contents: { type: "Array" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const circuitBreakerPathFilters = {
  type: { type: "String" },
  contents: { type: "Array" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const paths = {
  currentContent: { type: "Reference" },
  nextContents: { type: "Array" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const contents = {
  title: { type: "String" },
  tag: { type: "String", canChange: true, default: "" },
  phraseRef: { type: "Reference" },
  motionRef: { type: "Reference" },
  pictureRef: { type: "Reference" },
  pictureRetainFlag: { type: "Boolean" },
  childTallMotionRef: { type: "Reference" },
  playType: { type: "String" },
  callFlag: { type: "Boolean" },
  emergencyFlag: { type: "emergencyFlag" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const manualCommands = {
  manualCommandGroupRef: { type: "Reference" },
  title: { type: "String" },
  motionRef: { type: "Reference" },
  phraseRef: { type: "Reference" },
  pictureRef: { type: "Reference" },
  digitalOut: { type: "Number", replace: { original: [""], value: [null] } },
  digitalOutOneShotTime: { type: "Number", canChange: true, },
  timeoutType: { type: "String" },
  timeoutSec: { type: "Number" },
  playType: { type: "String" },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

const externalCommands = {
  title: { type: "String" },
  tag: { type: "String", canChange: true, default: "" },
  mode: { type: "String" },
  faceDetectionType: { type: "String" },
  faceDetectionPhraseMaps: {
    type: "Array",
    array: {
      type: "Map",
      map: {
        phraseType: { type: "String" },
        phraseRef: { type: "Reference" },
      }
    }
  },
  motionRef: { type: "Reference" },
  phraseRef: { type: "Reference" },
  pictureRef: { type: "Reference" },
  digitalOut: { type: "Number", replace: { original: [""], value: [null] }  },
  digitalOutOneShotTime: { type: "Number" },
  timeoutType: { type: "String" },
  timeoutSec: { type: "Number", replace: { original: ["0"], value: [0] } },
  playType: { type: "String" },
  callFlag: { type: "Boolean" },
  emergencyFlag: { type: "emergencyFlag" },
  callSuccess: {
    type: "Map",
    map: {
      motionRef: { type: "Reference" },
      phraseRef: { type: "Reference" },
      pictureRef: { type: "Reference" },
      digitalOut: { type: "Number", replace: { original: [""], value: [null] }  },
      digitalOutOneShotTime: { type: "Number" },
      timeoutType: { type: "String" },
      timeoutSec: { type: "Number" },
      playType: { type: "String" },
    }
  },
  callFail: {
    type: "Map",
    map: {
      motionRef: { type: "Reference" },
      phraseRef: { type: "Reference" },
      pictureRef: { type: "Reference" },
      digitalOut: { type: "Number", replace: { original: [""], value: [null] }  },
      digitalOutOneShotTime: { type: "Number" },
      timeoutType: { type: "String" },
      timeoutSec: { type: "Number" },
      playType: { type: "String" },
    }
  },
  callReject: {
    type: "Map",
    map: {
      motionRef: { type: "Reference" },
      phraseRef: { type: "Reference" },
      pictureRef: { type: "Reference" },
      digitalOut: { type: "Number", replace: { original: [""], value: [null] }  },
      digitalOutOneShotTime: { type: "Number" },
      timeoutType: { type: "String" },
      timeoutSec: { type: "Number" },
      playType: { type: "String" },
    }
  },
  createdAt: { type: "Timestamp" },
  updatedAt: { type: "Timestamp" },
}

export const DEFINITIONS = {
  companies: {
    definition: companies,
    collections: {
      labels: {
        definition: labels,
        collections: {
          vguards: {
            definition: vguards,
            collections: {
              schedules: {
                definition: schedules
              }
            }
          },
          "guide-pictures": {
            definition: guidePictures,
          },
          characters: {
            definition: characters,
            collections: {
              nearZoneGreetings: {
                definition: nearZoneGreetings
              },
              midZoneGreetings: {
                definition: midZoneGreetings
              },
              sttPathFilters: {
                definition: sttPathFilters
              },
              cameraPathFilters: {
                definition: cameraPathFilters
              },
              callPathFilters: {
                definition: callPathFilters
              },
              circuitBreakerPathFilters: {
                definition: circuitBreakerPathFilters
              },
              paths: {
                definition: paths
              },
              contents: {
                definition: contents
              },
              manualCommands: {
                definition: manualCommands
              },
              externalCommands: {
                definition: externalCommands
              },
            }
          }
        }
      },
      "approval-requests": {
        definition: approvalRequests,
      },
      "tool-managers": {
        definition: toolManagers,
      },
      "tool-users": {
        definition: toolUsers,
      },
      phrases: {
        definition: phrases,
      },
      faceDetectionPhrases: {
        definition: faceDetectionPhrases,
      },
      motions: {
        definition: motions,
        default_data: motions_default_data,
      },
      models: {
        definition: models,
        default_data: models_default_data,
      },
      parser: {
        collections: {
          keywords: {
            definition: keywords,
          },
          keywordGroups: {
            definition: keywordGroups,
          }
        }
      },
      manualCommandsGroups: {
        definition: manualCommandsGroups
      },
    }
  }
};
