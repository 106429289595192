import { Component } from 'react';
import { withRouter } from 'react-router-dom'
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sessionLogin } from './actions/login';

export class Auth extends Component {

  componentDidMount() {
    this.checkAuth();
  }

  componentDidUpdate() {
    this.checkAuth();
  }

  checkAuth() {
    const sessionUser = sessionStorage.getItem("admin_user");
    if (this.props.location.pathname !== '/login' && this.props.user == null && sessionUser == null) {
      this.props.history.replace('/login');
    } else if(this.props.user == null && sessionUser){
      // 画面リロードした場合
      sessionLogin(this.props.dispatch, JSON.parse(sessionUser)).then(() => {
        this.props.history.push("/");
      });
    }
  }

  render() {
    return '';
  }
}

//Auth.contextTypes = {
//    router: PropTypes.object
//};

export default withRouter(connect(state => ({ user: state.user && state.user.user }))(Auth));
