import React from 'react';

export default function Trash(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 10H24C24.55 10 25 10.45 25 11C25 11.55 24.55 12 24 12H23.84L23.01 22.35C23.01 23.81 21.82 25 20.36 25H11.65C10.19 25 9 23.81 9 22.35L8.16 12H8C7.45 12 7 11.55 7 11C7 10.45 7.45 10 8 10H12V9C12 7.9 12.9 7 14 7H18C19.1 7 20 7.9 20 9V10ZM18 9H14V10H18V9ZM21 22.27L21.01 22.19L21.83 12H10.17L10.99 22.19L11 22.27V22.35C11 22.7 11.29 23 11.65 23H20.35C20.7 23 21 22.7 21 22.35V22.27ZM14 21C14 21.55 13.55 22 13 22C12.45 22 12 21.55 12 21V14C12 13.45 12.45 13 13 13C13.55 13 14 13.45 14 14V21ZM16 22C16.55 22 17 21.55 17 21V14C17 13.45 16.55 13 16 13C15.45 13 15 13.45 15 14V21C15 21.55 15.45 22 16 22ZM20 21C20 21.55 19.55 22 19 22C18.45 22 18 21.55 18 21V14C18 13.45 18.45 13 19 13C19.55 13 20 13.45 20 14V21Z" fill="white" />
    </svg>
  );
}
