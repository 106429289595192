import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Login from './login';
import Main from './main';
import createFinalStore from './store';
import CompanyForm from './companyForm';
import AdminForm from './adminForm';
import CompanyAccounts from './companyAccounts';
import PasswordForm from './passwordForm';
import env from './utilities/env';
import { NotFound } from './notFound';

export default class App extends Component {

  constructor(props) {
    super(props);
    this.store = createFinalStore();
    this.theme = createMuiTheme({
      overrides: {
        MuiTableRow: {
          root: {
            width: '100%'
          }
        },
        MuiTableCell: {
          root: {
            height: '48px',
            fontFamily: 'Hiragino Kaku Gothic Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: '48px',
            padding: '0 16px'
          },
          head: {
            position: 'sticky',
            top: '0',
            zIndex: '1',
            textAlign: 'center',
            fontWeight: '600',
            background: '#FFFFFF',
          }
        },
        MuiTextField: {
          root: {
            width: '100%'
          }
        },
        MuiInputBase: {
          root: {
            width: '100%',
            lineHeight: '1em'  
          },
        },
        MuiInput: {
          input: {
            height: '1em',
            padding: '0',
            fontFamily: 'Hiragino Kaku Gothic Pro',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            textAlign: 'left',
            color: '#444444'
          },
          underline: {
            '&:before': {
              borderBottom: '0',
              transition: null,
            },
            '&:after': {
              borderBottom: '0'
            },
            '&:hover:not($disabled):before': {
              borderBottom: '1px solid #DDDDDD',
            },
            '&:hover:not($disabled):after': {
              borderBottom: '1px solid #DDDDDD',
            }
          }
        },
        MuiList: {
          padding: {
            paddingTop: '0',
            paddingBottom: '0',
          },
        },
        MuiMenu: {
          list: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }
        },
        MuiMenuItem: {
          root: {
            width: '100%',
            display: 'block',
            textAlign: 'center',
          }
        },
        MuiSelect: {
          select: {
            '&:focus': {
              backgroundColor: 'none',
            },
          },
        },
        MuiPaper: {
          elevation8: {
            '&.MuiPaper-rounded': {
              borderRadius: '20px',
            }
          },
        },
        MuiListItem: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#EEEEEE',                
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#EEEEEE',
            },
          }
        },
      },
    });
    const firebaseConfig = {
      apiKey: env('API_KEY'),
      authDomain: env('AUTH_DOMAIN'),
      databaseURL: env('DATABASE_URL'),
      projectId: env('PROJECT_ID'),
      storageBucket: env('STORAGE_BUCKET'),
      messagingSenderId: env('MESSAGING_SENDER_ID'),
      appId: env('APP_ID')
    };
    firebase.initializeApp(firebaseConfig);
  }

  render() {
    return (
      <ThemeProvider theme={this.theme}>
        <Provider store={this.store}>
          <BrowserRouter>
            <Switch>
              <Route exact path='/login'>
                <Login />
              </Route>
              <Route exact path="/">
                <Main />
              </Route>
              <Route exact path="/companyForm">
                <CompanyForm />
              </Route>
              <Route exact path="/companyForm/:cid">
                <CompanyForm />
              </Route>
              <Route exact path="/adminForm">
                <AdminForm />
              </Route>
              <Route exact path="/accounts/:cid">
                <CompanyAccounts />
              </Route>
              <Route exact path="/password">
                <PasswordForm />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    )
  }

}