import "firebase/firestore";
import db, { deleteCollection } from '../utilities/db';
import functions, { isFunctionsSdkInternalError, isTokenRevokedError } from '../utilities/functions';
import { inlineErrorAction, dialogErrorAction, offLineErrorAction, libraryErrorAction } from "./appAction";
import { redirectUnauthorizeUser } from "./login";

const VGUARD_CHILD_COLLECTIONS = ["commands", "heartbeats", "schedules", "setting-updates"];

export async function listCompanyAccounts(dispatch, selectCompany) {
  try {
    const company = db().collection("companies").doc(selectCompany.cid);
    const managers = await company.collection("tool-managers").get();
    const users = await company.collection("tool-users").get();
    const labels = await Promise.all(
      selectCompany.labels.map(async label => {
        const vguards = await company.collection("labels").doc(label.label).collection("vguards").orderBy("createdAt", "asc").get();
        return {
          ...label,
          vguards: vguards.docs.map(item => {
            return {
              ...item.data(),
              vguard: item.id
            };
          })
        };
      })
    );
    const approvalRequests = await company.collection('approval-requests').get();
    const requestedUsers = approvalRequests.docs.reduce((acm, r) => {
      const data = r.data();
      let name;
      //console.log(data);
      switch (data.role) {
        case 'tool-manager':
          name = 'managers';
          break;
        case 'tool-user':
          name = 'users';
          break;
        default:
          console.log('approval-requests user ' + r.id + ' has bad role ' + r.role);
          return acm;
      }
      acm[name].push({
        uid: r.id,
        displayName: data.displayName,
        createdAt: data.createdAt,
        email: data.email,
        approval: false,
      });
      return acm;
    }, { managers: [], users: [] });
    //console.log(requestedUsers);
    dispatch(
      companyAccountAction({
        cid: selectCompany.cid,
        companyName: selectCompany.companyName,
        managers: managers.docs.map(item => {
          return {
            ...item.data(),
            uid: item.id,
            approval: true,
          };
        }).concat(requestedUsers.managers),
        users: users.docs.map(item => {
          return {
            ...item.data(),
            uid: item.id,
            approval: true,
          };
        }).concat(requestedUsers.users),
        labels: labels
      })
    );
  } catch (e) {
    dispatch(inlineErrorAction('アカウント取得に失敗しました'));
  }
}

export function beginCreateVguardAccount(dispatch, vguardAccount) {
  if (vguardAccount) {
    dispatch(beginEditVguardAccountAction(vguardAccount.vguard, vguardAccount.displayName, vguardAccount.label));
  } else {
    dispatch(beginCreateVguardAccountAction());
  }
}

export function cancelCreateVguardAccount(dispatch) {
  dispatch(cancelCreateVguardAccountAction());
}

export async function editVguardAccount(dispatch, name, value){
  dispatch(editVguardAccountAction(name, value));
}

export async function deleteVguardAccount(dispatch, cid, labelId, vguardId){
  try{
    const vguardDoc = db().collection("companies").doc(cid).collection("labels").doc(labelId).collection("vguards").doc(vguardId);
    const vguard = await vguardDoc.get();
    if (!vguard.exists) {
      dispatch(dialogErrorAction("このバーチャル警備員アカウントは既に削除されています。"));
      return;
    }
    await Promise.all(VGUARD_CHILD_COLLECTIONS.map(async collection => {
      await deleteCollection(db(), vguardDoc.collection(collection));
    }));
    await vguardDoc.delete();
    dispatch(deleteVguardAccountAction(labelId, vguardId));
    return true;
  } catch(e){
    if (!navigator.onLine || (e.name === 'FirebaseError' && e.code === 'unavailable')) {
      dispatch(offLineErrorAction('バーチャル警備員アカウント削除'));
      return;
    }
    dispatch(dialogErrorAction(e));
  }
}

export async function updateVguardAccount(dispatch, cid, vguardAccount) {
  if (vguardAccount.vguard) {
    await updateExistingVguardAccount(dispatch, cid, vguardAccount);
  } else {
    await addVguardAccount(dispatch, cid, vguardAccount);
  }
}

async function addVguardAccount(dispatch, cid, newVguard){
  // functions呼び出してアカウント登録
  try {
    const registerVguardAccountResult = await (functions().httpsCallable('registerVguardAccount'))({
      displayName: newVguard.displayName,
      cid: cid,
      label: newVguard.label,
    });
    //console.log("registerVguardAccountResult", registerVguardAccountResult.data, cid, newVguard.label);
    let vguardDoc = db().collection("companies").doc(cid)
                        .collection("labels").doc(newVguard.label)
                        .collection("vguards").doc(registerVguardAccountResult.data.vguard);
    //console.log("listenスタート");
    let listenSuccess = false
    let unsub = vguardDoc.onSnapshot((snapshot) => {
      //console.log("listen!!!!");
      // console.log(snapshot, snapshot.data());
      if(snapshot.data() && snapshot.data().settings){
        // console.log("listen終了");
        listenSuccess = true;
        unsub();
        dispatch(listenEndVguardAccountAction());
      }
    }, err => {
      // console.log("listen failed");
      //console.log(err);
    });
    setTimeout(()=>{
      //console.log("timeout");
      unsub();
      if (!listenSuccess) {
        dispatch(listenEndVguardAccountAction());
        dispatch(dialogErrorAction('バーチャル警備員アカウントの作成に失敗した可能性があります。'));
      }
    }, 10000);
    dispatch(addVguardAccountAction({
      ...registerVguardAccountResult.data,
      label: newVguard.label
    }));
  } catch (e) {
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("バーチャル警備員アカウント作成"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    if (e.code === 'already-exists' && e.toString().indexOf("vguardMaxCount") !== -1) {
      dispatch(inlineErrorAction("バーチャル警備員上限に達しています"));
      return;
    }
    dispatch(inlineErrorAction(e));
  }
}

async function updateExistingVguardAccount(dispatch, cid, vguardAccount) {
  try {
    const vguardDoc = db().collection("companies").doc(cid)
                          .collection("labels").doc(vguardAccount.label)
                          .collection("vguards").doc(vguardAccount.vguard);
    const vguard = await vguardDoc.get();
    if (!vguard.exists) {
      dispatch(dialogErrorAction("このバーチャル警備員アカウントは既に削除されています。"));
      return;
    }
    vguardDoc.update({
      displayName: vguardAccount.displayName,
    });
    dispatch(updateVguardAccountAction(vguardAccount.vguard, vguardAccount.displayName, vguardAccount.label));
  } catch (e) {
    if (!navigator.onLine || (e.name === 'FirebaseError' && e.code === 'unavailable')) {
      dispatch(offLineErrorAction('バーチャル警備員アカウント更新'));
      return;
    }
    dispatch(inlineErrorAction(e));
  }
}

function companyAccountAction(companyAccounts) {
  return {
    type: "LIST_COMPANY_ACCOUNTS",
    payload: {
      companyAccounts: companyAccounts
    }
  };
}

function beginCreateVguardAccountAction() {
  return {
    type: 'BEGIN_CREATE_VGUARD_ACCOUNT',
  }
}

function beginEditVguardAccountAction(vguard, displayName, label) {
  return {
    type: 'BEGIN_EDIT_VGUARD_ACCOUNT',
    payload: {
      vguard: vguard,
      displayName: displayName,
      label: label,
    }
  }
}

function cancelCreateVguardAccountAction() {
  return {
    type: 'CANCEL_CREATE_VGUARD_ACCOUNT',
  }
}


function editVguardAccountAction(name, value){
  return {
    type: 'EDIT_VGUARD_ACCOUNT',
    payload: {
      name: name,
      value: value,
    }
  }
}

function addVguardAccountAction(vguardAccount){
  return {
    type: "ADD_VGUARD_ACCOUNT",
    payload: {
      vguardAccount: vguardAccount
    }
  }
}

function updateVguardAccountAction(vguard, displayName, label) {
  return {
    type: "UPDATE_VGUARD_ACCOUNT",
    payload: {
      vguard: vguard,
      displayName: displayName,
      label: label,
    }
  }
}

function deleteVguardAccountAction(labelId, vguardId){
  return {
    type: "DELETE_VGUARD_ACCOUNT",
    payload: {
      labelId: labelId,
      vguardId: vguardId,
    }
  };
}

function listenEndVguardAccountAction(){
  return {
    type: "LISTEN_END_VGUARD_ACCOUNT_ACTION",
  };
}
