import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { login, setUserError } from './actions/login';
import Logo from './svg/Logo';

export class Login extends Component {
  state = {
    email: '',
    password: '',
    dirty: false,
    executing: false,
  }

  localizeMessage(message) {
    if (message) {
      if (message.message) {
        message = message.message;
      } else if (typeof message !== 'string') {
        message = message.toString();
      }
    }
    switch (message) {
      case 'authorization failed':
        return 'メールまたはパスワードが異なります';
      case 'no role':
        return '権限が不足しています';
      case 'no value':
        return '入力内容が不足しています';
      case 'account deleted':
        return 'お使いのユーザは他のアドミニストレーターによって削除されました';
      case 'unauthorized user':
        return '他の端末でログアウトしたかパスワードが変更されたため、もう一度ログインしてください';
      default:
        return message;
    }
  }

  componentDidUpdate() {
    if (this.props.user.user) {
      this.setState({
        dirty: false,
        executing: false
      });
      return this.props.history.replace('/');
    }
    if (this.state.executing && this.props.user.error) {
      this.setState({
        executing: false,
        dirty: false,
      })
    }
  }

  onChange = e => {
    const state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onKeyUp = (e) => {
    // ブラウザによる自動補完でdirtyが変わってしまうのを防ぐため、onKeyUpで監視
    if (e.keyCode) {
      this.setState({
        dirty: true,
      });
    }
  }

  onLogin = e => {
    e.preventDefault();

    if (this.state.executing) {
      return;
    }

    const email = this.state.email;
    const password = this.state.password;

    if (email !== '' && password !== '') {
      this.setState({
        executing: true,
      }, () => login(this.props.dispatch, email, password));
    } else {
      this.setState({
        executing: true,
      }, () => setUserError(this.props.dispatch, 'no value'));
    }
  }

  render() {
    const clz = this.props.classes;
    return (
      <div className={clz.mainPanel}>
        <form>
          <div className={clz.box + ' ' + clz.vguardLabel}><Logo /><span className={clz.textLogo}>vguard</span></div>
          <div className={clz.box + ' ' + clz.vguardTitle}>会社管理ツール</div>
          <div className={clz.box + ' ' + clz.email}><input className={clz.textField} name="email" placeholder="メールアドレス" value={this.state.email} onChange={this.onChange} onKeyUp={this.onKeyUp} /></div>
          <div className={clz.box + ' ' + clz.password}><input className={clz.textField} name="password" placeholder="パスワード" value={this.state.password} type="password"  onChange={this.onChange} onKeyUp={this.onKeyUp} /></div>
          <div className={clz.box + ' ' + clz.status}>{!this.state.executing && this.state.dirty ? ''  : this.localizeMessage(this.props.user.error)}</div>
          <div className={clz.box}><button className={clz.loginButton} onClick={this.onLogin}>ログイン</button></div>
          <div className={clz.box + ' ' + clz.reminder}><a className={clz.reminder} href="mailto:test@test.com">パスワードを忘れてしまった場合</a></div>
        </form>
      </div>
    );
  }
}

const styles = {
  mainPanel: {
    backgroundColor: '#38496D',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  box: {
    minWidth: '100vw',
    textAlign: 'center',
    margin: 0
    //display: 'inline-block'
  },
  vguardLabel: {
    height: '64px'
  },
  textLogo: {
    verticalAlign: 'top',
    marginLeft: '12px',
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '48px',
    lineHeight: '48px',
    color: '#FFFFFF'
  },
  vguardTitle: {
    marginTop: '20px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF'
  },
  textField: {
    width: '380px',
    height: '48px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#444444',
    background: '#EEEEEE',
    borderRadius: '24px',
    outline: 'none',
    border: 'none',
  },
  email: {
    marginTop: '40px',
  },
  password: {
    marginTop: '24px',
  },
  status: {
    marginTop: '24px',
    minHeight: '24px',
    color: '#FFFFFF',
  },
  loginButton: {
    marginTop: '16px',
    width: '160px',
    height: '48px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '27px',
    background: '#FFFFFF',
    color: '#38496D',
    borderRadius: '24px',
    borderStyle: 'none',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    '&:hover': {
      backgroundColor: '#CCCCCC',
    },
    '&:disabled': {
      backgroundColor: '#D3D3D3',
      cursor: 'default',
    },
  },
  reminder: {
    marginTop: '40px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#FFFFFF'
  }
};

Login.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(withRouter(connect(state => ({
  user: state.user,
}))(Login)));
