import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Search from './svg/Search';
import { searchCompany } from "./actions/companies";
import { searchAdmin } from "./actions/admin";

const styles = {
  adminBar: {
    width: '100%',
    height: '32px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftSide: {
    display: 'flex',
    height: '32px',
    minWidth: '580px',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  rightSide: {
    display: 'flex',
    height: '32px',
    minWidth: '316px',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  title: {
    height: '32px',
    width: '112px',
    minWidth: '112px',
    marginRight: '24px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#444444'
  },
  tab: {
    verticalAlign: 'middle',
    height: '32px',
    margin: '0',
    padding: '0 10px',
    minWidth: '140px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '32px',
    textAlign: 'center',
  },
  main: {
    width: '120px',
    minWidth: '70px',
  },
  adminForm: {
    width: '193px',
    minWidth: '193px',
  },
  password: {
    width: '120px',
    minWidth: '120px',
  },
  onTab: {
    background: '#38496D',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)',
    borderRadius: '8px',
    color: '#FFFFFF',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: "#4360AA",
    },
  },
  offTab: {
    background: 'transparent',
    color: '#38496D',
    cursor: 'pointer',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: "#CCCCCC",
    },
  },
  searchBox: {
    width: '272px',
    height: '32px',
    marginRight: '16px',
    borderRadius: '16px',
  },
  searchText: {
    height: '32px',
    width: '100%',
    minWidth: '100px',
    border: 'none',
    background: '#EEEEEE',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#AAAAAA',
    outline: 'none',
    padding: '0 0 0 36px',
    borderRadius: '16px',
  },
  search: {
    width: '32px',
    minWidth: '32px',
    height: '32px',
    position: 'relative',
    left: '36px',
  },
  new: {
    minWidth: '120px',
    height: '32px',
    background: '#FFB72C',
    borderRadius: '16px',
    borderStyle: 'none',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '32px',
    textAlign: 'center',
    color: '#FFFFFF',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFE456',
    },
    '&:disabled': {
      backgroundColor: '#D3D3D3',
      cursor: 'default',
    },
  },
};

class Adminbar extends Component {

  constructor(props) {
    super(props);
    this.state = { searchText: '' };
  }

  getTabClz = (me) => (
    this.props.classes.tab
    + ' ' + this.props.classes[this.props.myState === me ? 'onTab' : 'offTab']
    + ' ' + (this.props.classes[me] || '')
  )

  onChangeSearchText = (e) => {
    e.preventDefault();
    if (this.props.myState === 'main') {
      searchCompany(this.props.dispatch, e.target.value);
    }
    if (this.props.myState === 'adminForm') {
      searchAdmin(this.props.dispatch, e.target.value);
    }
    this.setState({
      searchText: e.target.value
    });
  }

  onSelect = (me, path) => {
    if (this.props.myState === me) {
      return;
    }
    if (typeof this.props.isAllow == 'function' && !this.props.isAllow()) {
      return;
    }
    this.props.history.push(path || me);
  }

  onMain = () => (this.onSelect('main', './'))
  onAdminForm = () => (this.onSelect('adminForm'))
  onPassword = () => (this.onSelect('password'))

  onNewButton = e => {
    e.preventDefault();
    if (this.props.onNew) {
      this.props.onNew(e);
    }
  }

  render() {
    const clz = this.props.classes;
    const searchStyle = {
      display: this.props.searchBarIsEnabled ? 'inline-flex' : 'none'
    };
    const newStyle = {
      display: this.props.newButtonIsEnabled ? 'block' : 'none'
    };
    return (
      <div className={clz.adminBar}>
        <div className={clz.leftSide}>
          <div className={clz.title}>会社管理</div>
          <div className={this.getTabClz('main')} onClick={this.onMain}>一覧</div>
          <div className={this.getTabClz('adminForm')} onClick={this.onAdminForm}>アドミニストレーター管理</div>
          <div className={this.getTabClz('password')} onClick={this.onPassword}>パスワード変更</div>
        </div>
        <div className={clz.rightSide}>
          <div className={clz.searchBox} style={searchStyle}>
            <Search className={clz.search} style={searchStyle} />
            <input className={clz.searchText} ref="adminBarSearch" defaultValue={this.props.searchText ? this.props.searchText : this.state.searchText} onChange={this.onChangeSearchText} />
          </div>
          <button className={clz.new} onClick={this.onNewButton} style={newStyle}>
            新規登録
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(
  connect(state => ({
    onFilter: state.adminBar && state.adminBar.onFilter,
    currentTab: state.adminBar && state.adminBar.currentTab
  }))(Adminbar)
));
