import firebase from 'firebase/app';
import 'firebase/functions';

let _functions;

export default function functions() {
  if (!_functions) {
    const host = process.env.REACT_APP_FUNCTIONS_EMULATOR_HOST;
    if (host) {
      firebase.functions().useFunctionsEmulator('http://' + host);
      _functions = firebase.functions();
    } else {
      _functions = firebase.app().functions('asia-northeast1');
    }
  }
  return _functions;
}

export function isFunctionsSdkInternalError(error) {
  // httpsCallableは不明なエラーはすべてinternalで返す
  if (error && error.code === "internal" && error.message === "internal" && error.details === undefined) {
    return true;
  }
  return false;
}

export function isTokenRevokedError(error) {
  if (error.code === "permission-denied" && error.details && error.details.code === "auth/id-token-revoked") {
    return true;
  }
  return false;
}
