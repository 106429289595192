const defaultState = {
  companyAccounts: null,
  vguardAccount: null,
  isListen: false,
};

function defaultVguardAccount(labels) {
  return {
    label: labels && labels.length > 0 ? labels[0].label : "",
    displayName: "",
  }
}

const labelVguardMax = accounts => {
  accounts.labels.forEach(label => {
    // label.isVguardMax = label.vguardMaxCount === label.vguards.length;
    label.isVguardMax = label.vguardMaxCount <= label.vguards.length;
  });
  accounts.isAllVguardMax = accounts.labels.every(x => x.isVguardMax);
  return accounts;
}

const updateCompanyAccounts = (companyAccount, label, vguardAccount) => {
  const newCompanyAccount = { ...companyAccount };
  const updatedLabel = newCompanyAccount.labels.find(x => x.label === label);
  if (updatedLabel) {
    if (!updatedLabel.vguards.find(x => x.vguard === vguardAccount.vguard)) {
      // 新規作成
      updatedLabel.vguards.push(vguardAccount);
      return newCompanyAccount;
    }

    // 編集：labelとvguardの参照を更新していかないとstateに検知されない
    const labelIndex = newCompanyAccount.labels.findIndex(x => x.label === label);
    const vguardIndex = newCompanyAccount.labels[labelIndex].vguards.findIndex(x => x.vguard === vguardAccount.vguard);

    newCompanyAccount.labels = [ ...newCompanyAccount.labels ];
    newCompanyAccount.labels[labelIndex] = { ...newCompanyAccount.labels[labelIndex] };
    newCompanyAccount.labels[labelIndex].vguards = [ ...newCompanyAccount.labels[labelIndex].vguards ];
    newCompanyAccount.labels[labelIndex].vguards[vguardIndex] = {
      ...updatedLabel.vguards[vguardIndex],
      displayName: vguardAccount.displayName,
    };
  }
  return newCompanyAccount;
}

const companyAccounts = (state = defaultState, action) => {
  switch (action.type) {
    case "LIST_COMPANY_ACCOUNTS":
      return {
        ...state,
        companyAccounts: labelVguardMax({ ...action.payload.companyAccounts }),
        vguardAccount: null,
      };
    case 'BEGIN_CREATE_VGUARD_ACCOUNT':
      return {
        ...state,
        vguardAccount: defaultVguardAccount(state.companyAccounts.labels),
      }
    case 'BEGIN_EDIT_VGUARD_ACCOUNT':
      return {
        ...state,
        vguardAccount: {
          vguard: action.payload.vguard,
          displayName: action.payload.displayName,
          label: action.payload.label,
        },
      }
    case 'EDIT_VGUARD_ACCOUNT':
      const vguardAccount = { ...state.vguardAccount };
      vguardAccount[action.payload.name] = action.payload.value;
      return {
        ...state,
        vguardAccount: vguardAccount,
      }
    case 'CANCEL_CREATE_VGUARD_ACCOUNT':
      return {
        ...state,
        vguardAccount: null,
        isListen: false,
      }
    case "ADD_VGUARD_ACCOUNT":
      const newAccounts = updateCompanyAccounts(state.companyAccounts, action.payload.vguardAccount.label, action.payload.vguardAccount);
      return {
        ...state,
        companyAccounts: labelVguardMax(newAccounts),
        vguardAccount: null,
        isListen: true,
      }
    case "UPDATE_VGUARD_ACCOUNT":
      const updatedAccounts = updateCompanyAccounts(state.companyAccounts, action.payload.label, { vguard: action.payload.vguard, displayName: action.payload.displayName });
      return {
        ...state,
        companyAccounts: updatedAccounts,
        vguardAccount: null,
      }
    case "LISTEN_END_VGUARD_ACCOUNT_ACTION":
      return {
        ...state,
        isListen: false,
      }
    case "DELETE_VGUARD_ACCOUNT":
      const liveAccounts = Object.assign({}, state.companyAccounts);
      liveAccounts.labels.some(x => {
        if (x.label === action.payload.labelId) {
          x.vguards = x.vguards.filter(x => x.vguard !== action.payload.vguardId);
          return true;
        } else {
          return false;
        }
      });
      return {
        ...state,
        companyAccounts: labelVguardMax(liveAccounts),
      }
    default:
      return state;
  }
};

export default companyAccounts;
