const defaultState = {
  adminUsers: [],
  editingUser: null,
  deletingUser: null,
  search: "",
  searchAdminUsers: [],
};

function defaultAdminUser() {
  return {
    uid: null,
    displayName: '',
    email: '',
    currentPassword: '',
    password: '',
    passwordConfirm: '',
  };
}

function search(adminUsers, search) {
  if (search) {
    adminUsers = adminUsers.filter(x => x.displayName.indexOf(search) >= 0);
  }
  return adminUsers;
}

const companies = (state = defaultState, action) => {
  let adminUsers, editingUser, index, searchAdminUsers;
  switch (action.type) {
    case 'ADMIN_USERS' :
      searchAdminUsers = search(action.payload.adminUsers, state.search);
      return {
        ...state,
        adminUsers: action.payload.adminUsers,
        editingUser: null,
        search: state.search,
        searchAdminUsers: searchAdminUsers,
      };
    case 'ADMIN_SEARCH' :
      searchAdminUsers = search(state.adminUsers, action.payload.search);
      return {
        ...state,
        search: action.payload.search,
        searchAdminUsers: searchAdminUsers,
      };
    case 'BEGIN_EDIT_ADMIN_USER' :
      editingUser = state.adminUsers.find(user => (
        user.uid === action.payload.uid
      ));
      return {
        ...state,
        editingUser: editingUser || defaultAdminUser(),
      };
    case 'BEGIN_EDIT_PASSWORD_ADMIN_USER':
      return {
        ...state,
        editingUser: {
          ...defaultAdminUser(),
          uid: action.payload.uid
        },
      };
    case 'EDIT_ADMIN_USER':
      return {
        ...state,
        editingUser: action.payload.editingUser,
      };
    case 'CANCEL_EDIT_ADMIN_USER':
      return {
        ...state,
        editingUser: null,
      };
    case 'UPDATE_ADMIN_USER' :
      editingUser = action.payload.editingUser.data;
      index = state.adminUsers.findIndex(user => (
        user.uid === editingUser.uid
      ));
      adminUsers = state.adminUsers.concat();
      if (index >= 0) {
        adminUsers[index] = editingUser;
      } else {
        adminUsers.push(editingUser);
      }
      searchAdminUsers = search(adminUsers, state.search);
      return {
        ...state,
        adminUsers: adminUsers,
        editingUser: null,
        searchAdminUsers: searchAdminUsers,
      };
    case 'BEGIN_DELETE_ADMIN_USER':
      editingUser = state.adminUsers.find(user => (
        user.uid === action.payload.uid
      ));
      return {
        ...state,
        deletingUser: editingUser,
      };

    case 'DELETE_ADMIN_USER':
      index = state.adminUsers.findIndex(user => (
        user.uid === action.payload.uid
      ));
      adminUsers = state.adminUsers.concat();
      if (index >= 0) {
        adminUsers.splice(index, 1);
      }
      searchAdminUsers = search(adminUsers, state.search);
      return {
        ...state,
        deletingUser: null,
        adminUsers: adminUsers,
        searchAdminUsers: searchAdminUsers,
      };
    case 'CANCEL_DELETE_ADMIN_USER':
      return {
        ...state,
        deletingUser: null,
      };
    default:
      return state;
  }
};

export default companies;
