import React from 'react';

export const NotFound = () => {
  return(
    <div style={{ backgroundColor: "white", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <div style={{ marginBottom: 40 }}>
            <img src="/images/404.png" alt="404"/>
          </div>
          <div style={{ marginBottom: 40 }}>
            <img src="/images/NotFound.png" alt="Not Found"/>
          </div>
          <div>
            <img src="/images/404_word.png" alt="お探しのページは見つかりませんでした。"/>
          </div>
        </div>
        <div style={{ width: 100 }}></div>
        <div>
          <img src="/images/secom_female_front.png" alt="female" />
        </div>
      </div>
    </div>
  )
};