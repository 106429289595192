import firebase from 'firebase/app';
import 'firebase/firestore';
import db from '../utilities/db';
import functions, { isFunctionsSdkInternalError, isTokenRevokedError } from '../utilities/functions';
import { DEFINITIONS } from '../utilities/collection_definition';
import { inlineErrorAction, offLineErrorAction, libraryErrorAction } from './appAction';
import { redirectUnauthorizeUser } from './login';

export async function listCompanies(dispatch, currentCompanies, numRows) {
  try {
    let query = await db().collection('companies').orderBy('createdAt', 'desc');
    if (currentCompanies.length) {
      const last = currentCompanies[currentCompanies.length - 1];
      query = query.startAfter(last.createdAt)
    }
    const docs = await query.limit(numRows).get();
    const companies = currentCompanies.concat(await Promise.all(docs.docs.map(async doc => {
      const labels = await doc.ref.collection('labels').get();
      return {
        ...doc.data(),
        cid: doc.id,
        labels: labels.docs.map(doc => {
          return {
            ...doc.data(),
            label: doc.id
          };
        })
      };
    })));
    dispatch(companiesAction(companies));
  } catch (e) {
    dispatch(inlineErrorAction(e));
  }
}

export function doSortChange(dispatch, doSort){
  dispatch({ type: "COMPANIES_DO_SORT_CHANGE", doSort: doSort });
}

export async function addCompany(dispatch) {
  dispatch(addCompanyAction());
}

export async function addLabel(dispatch) {
  dispatch(addLabelAction());
}

export async function deleteLabel(dispatch, index) {
  dispatch(deleteLabelAction(index));
}

export async function defaultLabel(dispatch) {
  dispatch(defaultLabelAction());
}

export async function editCompany(dispatch, company, actives) {
  if (company) {
    if (company.cid && !actives) {
      const companyDoc = db().collection('companies').doc(company.cid);
      actives = {};
      await Promise.all(
        company.labels.map(async label => {
          const vguards = await companyDoc.collection("labels").doc(label.label).collection("vguards")
            .where('using', '==', true).get();
          actives[label.label] = vguards.docs.length;
        })
      );
    }
    dispatch(editCompanyAction(company, actives));
  } else {
    dispatch(addCompanyAction());
  }
}

async function createCompany(dispatch, company) {
  try {
    const companyEditData = {
      cid: company.cid,
      companyName: company.companyName,
      companyContactName: company.companyContactName,
      companyContactDepartment: company.companyContactDepartment,
      companyContactTel: company.companyContactTel,
      companyContactMails: company.companyContactMails.filter(x => { return x; }),
      automaticApprovalMails: company.automaticApprovalMails.filter(x => { return x; }),
      createdAt: company.createdAt,
      updatedAt: company.updatedAt,
      companyDescription: company.companyDescription,
      labels: [ ...company.labels ],
      managerAccountEnabled: company.managerAccountEnabled,
    };
    const res = await (functions().httpsCallable('registerCompany'))(companyEditData);
    // console.log(res.data);
    ['createdAt', 'updatedAt'].forEach(key => {
      res.data[key] = new firebase.firestore.Timestamp.fromDate(new Date(res.data[key]));
    });
    res.data.labels.forEach(label => {
      label.createdAt = new firebase.firestore.Timestamp.fromDate(new Date(label.createdAt));
    });
    dispatch(updateCompanyAction(res.data));
  } catch (e) {
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("会社登録"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(inlineErrorAction(e));
  }
}

async function updateExistingCompany(dispatch, company) {
  try{
    const companyDoc = db().collection("companies").doc(company.cid);

    // DBとのラベル名重複チェック
    const editingNewLabels = company.labels.filter(x => !x.label && x.labelName);
    const editingExistingLabels = company.labels.filter(x => x.label && x.labelName);
    const labelDocs = (await companyDoc.collection("labels").get()).docs;
    const labels = labelDocs.map(label => ({
      ...label.data(),
      label: label.id,
    }));
    for (let label of editingNewLabels) {
      const labelDuplicateCheck = labels.find(data => data.labelName === label.labelName);
      if (labelDuplicateCheck) {
        dispatch(inlineErrorAction("ラベル名「" + label.labelName + "」は他のユーザによってすでに登録されています。"));
        return;
      }
    }
    // 既存ラベルの設置台数チェック
    for (let label of editingExistingLabels) {
      const labelCountCheck = labels.find(data => data.label === label.label);
      if (labelCountCheck.vguardCount > label.vguardMaxCount) {
        dispatch(inlineErrorAction("稼働台数より少ない設置台数は指定できません。"));
        return;
      }
    }
    // ラベルを新規作成
    let newLabels = [];
    if (editingNewLabels.length) {
      const response = await (functions().httpsCallable('registerCompanyLabel'))({
        cid: company.cid,
        labels: editingNewLabels.map(label => ({ labelName: label.labelName, vguardMaxCount: Number(label.vguardMaxCount), vguardCount: 0 })),
      });
      newLabels = response.data.map(label => ({
        ...label,
        createdAt: new firebase.firestore.Timestamp.fromDate(new Date(label.createdAt)),
      }));
    }
    // ラベルを更新
    const existingLabels = await Promise.all(editingExistingLabels.map(async label => {
      const labelEditData = {
        labelName: label.labelName,
        vguardMaxCount: Number(label.vguardMaxCount),
      };
      const labelDoc = companyDoc.collection("labels").doc(label.label);
      await labelDoc.update(labelEditData);
      return {
        ...labelEditData,
        vguardCount: Number(label.vguardCount),
        createdAt: label.createdAt,
        label: label.label,
      };
    }));
    const companyEditData = {
      companyName: company.companyName,
      companyContactName: company.companyContactName,
      companyContactDepartment: company.companyContactDepartment,
      companyContactTel: company.companyContactTel,
      companyContactMails: company.companyContactMails.filter(x => x),
      automaticApprovalMails: company.automaticApprovalMails.filter(x => x),
      createdAt: company.createdAt,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      companyDescription: company.companyDescription,
    };
    await companyDoc.update(companyEditData);
    dispatch(updateCompanyAction({
      ...companyEditData,
      updatedAt: firebase.firestore.Timestamp.now(),
      cid: companyDoc.id,
      labels: [ ...existingLabels, ...newLabels ],
    }));
  } catch(e){
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("会社更新"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(inlineErrorAction(e));
  }
}

export async function updateCompany(dispatch, company){
  if (company.cid) {
    await updateExistingCompany(dispatch, company);
  } else {
    await createCompany(dispatch, company);
  }
}

function getDefaultValue(fieldDefinition){
  let result = null;
  if(fieldDefinition.default !== undefined){
    console.log(fieldDefinition);
    result = fieldDefinition.default;
  } else if(fieldDefinition.type){
    switch(fieldDefinition.type.toLowerCase()){
      case "number":
      case "Float":
        result = 0;
        break;
      case "timestamp":
        result = firebase.firestore.FieldValue.serverTimestamp();
        break;
      case "map":
        result = {};
        for(let [key, value] of Object.entries(fieldDefinition.map)){
          result[key] = getDefaultValue(value);
        }
        break;
      default:
        result = null;
        break;
    }
  }
  return result;
}

// 定義にはあるがデータにはないフィールドがあった場合はそのデータのフィールドを追加
function checkDefinitionField(fieldName, fieldDefinition, fieldValue, changeFields){
  let isChange = false;
  if(typeof fieldValue === "undefined"){
    isChange = true;
    fieldValue = getDefaultValue(fieldDefinition);
    // console.log(fieldName, fieldDefinition, fieldValue);
    changeFields.push({ fieldName: fieldName, operation: "add", value: fieldValue, canChange: fieldDefinition.canChange });
  } else {
    if(fieldValue){
      if(fieldDefinition.type.toLowerCase() === "map" && fieldDefinition.map){
        for(let [key, value] of Object.entries(fieldDefinition.map)){
          const r = checkDefinitionField(key, value, fieldValue[key], changeFields);
          if(r.isChange && r.fieldDefinition.canChange) {
            // 変更があって、変更可と指定されている場合は値を変える
            fieldValue[key] = r.fieldValue;
          }
        }
      } else if(fieldDefinition.type.toLowerCase() === "array" && fieldDefinition.array){
        if(fieldDefinition.array.type.toLowerCase() === "map" && fieldDefinition.array.map){
          // MapのListの場合、arrayの中身を見る
          for(let v of fieldValue){
            for(let [key, value] of Object.entries(fieldDefinition.array.map)){
              const r = checkDefinitionField(key, value, v[key], changeFields);
              if(r.isChange && r.fieldDefinition.canChange) {
                // 変更があって、変更可と指定されている場合は値を変える
                v[key] = r.fieldValue;
              }
            }
          }
        }
      }
    }
  }
  // console.log(fieldName, fieldDefinition, fieldValue);
  return { fieldName: fieldName, fieldDefinition: fieldDefinition, fieldValue: fieldValue, isChange: isChange };
}

// データにはあるが定義にはないフィールドがあった場合はそのデータのフィールドを削除
function checkDataField(fieldName, fieldDefinition, fieldValue, changeFields){
  let isChange = false;
  if(typeof fieldDefinition === "undefined"){
    isChange = true;
    changeFields.push({ fieldName: fieldName, operation: "delete" });
    fieldName = null;
  } else {
    if(fieldValue){
      if(fieldDefinition.type.toLowerCase() === "map" && fieldDefinition.map){
        for(let key in fieldValue){
          const r = checkDataField(key, fieldDefinition.map[key], fieldValue[key], changeFields);
          if(r.isChange) console.log(r);
          if(r.fieldName === null){
            delete fieldValue[key];
          }
        }
      } else if(fieldDefinition.type.toLowerCase() === "array" && fieldDefinition.array){
        if(fieldDefinition.array.type.toLowerCase() === "map" && fieldDefinition.array.map){
          // MapのListの場合、arrayの中身を見る
          // console.log(fieldValue, fieldDefinition.array.map);
          for(let v of fieldValue){
            for(let key in v){
              const r = checkDataField(key, fieldDefinition.array.map[key], v[key], changeFields)
              if(r.isChange) console.log(r);
              if(r.fieldName === null){
                delete v[key];
              }
            }
          }
        }
      }
    }
  }
  // return { fieldName: fieldName };
  return { fieldName: fieldName, fieldDefinition: fieldDefinition, fieldValue: fieldValue, isChange: isChange };
}

// データの値の置換をチェック
function checkFieldReplace(fieldName, fieldDefinition, fieldValue, changeFields){
  let isChange = false;
  if(typeof fieldDefinition !== "undefined"){
    if(fieldDefinition.replace && fieldDefinition.replace.original.indexOf(fieldValue) >= 0){
      isChange = true;
      changeFields.push({ fieldName: fieldName, operation: "value_replace", original: fieldValue, value: fieldDefinition.replace.value[fieldDefinition.replace.original.indexOf(fieldValue)] });
    }
    if(fieldDefinition.type.toLowerCase() === "map" && fieldDefinition.map && fieldValue){
      for(let key in fieldValue){
        const r = checkFieldReplace(key, fieldDefinition.map[key], fieldValue[key], changeFields);
        if(r.isChange) {
          console.log(r);
          fieldValue[key] = r.fieldDefinition.replace.value[r.fieldDefinition.replace.original.indexOf(r.fieldValue)];
        }
      }
    } else if(fieldDefinition.type.toLowerCase() === "array" && fieldDefinition.array){
      if(fieldDefinition.array.type.toLowerCase() === "map" && fieldDefinition.array.map && fieldValue){
        // MapのListの場合、arrayの中身を見る
        console.log(fieldName, fieldDefinition, fieldValue);
        for(let v of fieldValue){
          for(let key in v){
            const r = checkFieldReplace(key, fieldDefinition.array.map[key], v[key], changeFields)
            if(r.isChange) {
              console.log(r);
              v[key] = r.fieldDefinition.replace.value[r.fieldDefinition.replace.original.indexOf(r.fieldValue)];
            }
          }
        }
      }
    }
  }
  return { fieldName: fieldName, fieldDefinition: fieldDefinition, fieldValue: fieldValue, isChange: isChange };
}

function checkField(snapshot, definition, isUpdate){
  const data = snapshot.data();
  const changeFields = [];
  for(let [key, value] of Object.entries(definition)){
    const r = checkDefinitionField(key, value, data[key], changeFields);
    data[key] = r.fieldValue;
  }
  for(let [key, value] of Object.entries(data)){
    const r = checkDataField(key, definition[key], value, changeFields, isUpdate);
    if(r.isChange) console.log(r);
    if(r.fieldName === null){
      delete data[key];
    }
    const r_replace = checkFieldReplace(key, definition[key], value, changeFields)
    if(r_replace.isChange) {
      console.log(r_replace);
      data[key] = r_replace.fieldDefinition.replace.value[r_replace.fieldDefinition.replace.original.indexOf(r_replace.fieldValue)];
    }
  }
  const result = [];
  if(changeFields.length){
    console.log(snapshot.ref.path, data);
    const ref = snapshot.ref;
    // 更新処理(上書き)
    if(isUpdate){
      if(data.updatedAt){
        data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      }
      ref.set(data);
    }
    // 結果を入れる
    result.push({
      path: ref.path,
      data: data,
      changeFields: changeFields,
    });
  }
  return result;
}

async function checkCollection(collectionName, definition, collections, default_data, parentDoc, result, isUpdate){
  let doc = parentDoc.collection(collectionName);
  let snapshots = await doc.get();
  if(definition) console.log("---" + collectionName + "--- start", snapshots.docs.length, snapshots.docs);
  for(let snapshot of snapshots.docs){
    // 定義データ(definition)がない場合は値のチェックを行わない
    if(definition){
      const r = checkField(snapshot, definition, isUpdate);
      if(r.length) {
        if(!result[collectionName]) result[collectionName] = [];
        result[collectionName].push(r);
      }
    }
    if(collections){
      // 子がある場合は、子のコレクションを処理する
      for(let [key, value] of Object.entries(collections)){
        await checkCollection(key, value.definition, value.collections, value.default_data, snapshot.ref, result, isUpdate);
      }
    }
  }

  if(default_data){
    // デフォルトデータがある場合
    const snapshot_ids = snapshots.docs.map(x => x.id);
    const defaults = default_data.data();
    const default_ids = defaults.map(x => x.id);
    for(let default_id of default_ids){
      if(snapshot_ids.indexOf(default_id) < 0){
        // defaultのidが見つからない場合は追加
        const d = { ...defaults.find(x => x.id === default_id) };
        delete d["id"];
        if(default_data.includeCreatedAt) d.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        if(default_data.includeUpdatedAt) d.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        if(d.modelRef){
          // modelRefがある場合
          d.modelRef = parentDoc.collection("models").doc(d.modelRef.model_id)
        }
        console.log("default data add", d)
        if(isUpdate) await doc.doc(default_id).set(d);
      } else {
        // 見つかった場合は中身の差分があるか確認
        const snapshot_data = snapshots.docs.find(x => x.id === default_id).data();
        const d = { ...defaults.find(x => x.id === default_id) };
        delete d["id"];
        if(d.modelRef){
          // modelRefがある場合
          d.modelRef = parentDoc.collection("models").doc(d.modelRef.model_id)
        }
        let diff = false;
        for(let field of Object.keys(d)){
          if(field === "modelRef"){
            if(d[field].id !== snapshot_data[field].id){
              diff = true;
              break;
            }
          } else if(d[field] !== snapshot_data[field]){
            diff = true;
            break;
          }
        }
        if(diff){
          if(default_data.includeUpdatedAt) d.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
          console.log("default data update", d)
          if(isUpdate) await doc.doc(default_id).update(d);
        }
      }
    }
  }

  if(definition)  console.log("---" + collectionName + "--- end");
  return result;
}

export async function checkData(dispatch, cid, isUpdate=false){
  const collectionName = "companies";
  const doc = db().collection("companies").doc(cid);
  const snapshot = await doc.get();
  const result = {};
  if(DEFINITIONS.companies.definition){
    console.log("---" + collectionName + "--- start", 1);
    const r = checkField(snapshot, DEFINITIONS.companies.definition, isUpdate);
    console.log("---" + collectionName + "--- end");
    if(r.length) {
      if(!result[collectionName]) result[collectionName] = [];
      result[collectionName].push(r);
    }
  }
  for(let [key, value] of Object.entries(DEFINITIONS.companies.collections)){
    await checkCollection(key, value.definition, value.collections, value.default_data, doc, result, isUpdate)
  }
  console.log(result);
}

export function searchCompany(dispatch, search){
  dispatch({
    type: "COMPANY_SEARCH",
    payload: {
      search: search,
    }
  });
}

function companiesAction(companies) {
  return {
    type: 'COMPANIES',
    payload: {
      companies: companies
    }
  }
}

function addCompanyAction() {
  return {
    type: "ADD_COMPANY"
  };
}

function addLabelAction() {
  return {
    type: "ADD_LABEL"
  };
}

function deleteLabelAction(index) {
  return {
    type: "DELETE_LABEL",
    payload: {
      deletingLabelIndex: index
    }
  };
}

function defaultLabelAction() {
  return {
    type: "DEFAULT_LABEL",
  };
}

function editCompanyAction(company, actives){
  return {
    type: "EDIT_COMPANY",
    payload: {
      selectedCompany: company,
      actives: actives,
    }
  };
}

function updateCompanyAction(company) {
  return {
    type: "UPDATE_COMPANY",
    payload: {
      selectedCompany: company
    }
  };
}
