import React from 'react';

export default function Edit(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16" />
      <path d="M13.6569 21.435L13.9731 22.3837C14.1204 22.3346 14.2542 22.2519 14.364 22.1421L13.6569 21.435ZM9.41424 22.8492L8.46556 22.533C8.34578 22.8923 8.4393 23.2885 8.70714 23.5563C8.97497 23.8242 9.37114 23.9177 9.73047 23.7979L9.41424 22.8492ZM10.8285 18.6066L10.1214 17.8995C10.0116 18.0093 9.92887 18.1431 9.87977 18.2904L10.8285 18.6066ZM22.8493 8C22.0682 7.21895 20.8019 7.21895 20.0208 8L21.4351 9.41421L21.4351 9.41421L22.8493 8ZM24.2635 9.41421L22.8493 8L21.4351 9.41421L22.8493 10.8284L24.2635 9.41421ZM24.2635 12.2426C25.0445 11.4616 25.0445 10.1953 24.2635 9.41421L22.8493 10.8284L24.2635 12.2426ZM16.4853 20.0208L24.2635 12.2426L22.8493 10.8284L15.0711 18.6066L16.4853 20.0208ZM15.0711 18.6066L13.922 19.7557L15.3363 21.1699L16.4853 20.0208L15.0711 18.6066ZM13.922 19.7557L12.9498 20.7279L14.364 22.1421L15.3363 21.1699L13.922 19.7557ZM9.73047 23.7979L13.9731 22.3837L13.3407 20.4863L9.09802 21.9006L9.73047 23.7979ZM9.87977 18.2904L8.46556 22.533L10.3629 23.1655L11.7771 18.9228L9.87977 18.2904ZM11.5356 19.3137L12.5078 18.3414L11.0936 16.9272L10.1214 17.8995L11.5356 19.3137ZM12.5078 18.3414L13.6569 17.1924L12.2427 15.7782L11.0936 16.9272L12.5078 18.3414ZM20.0208 8L12.2427 15.7782L13.6569 17.1924L21.4351 9.41421L20.0208 8Z" fill="white" />
      <path d="M11.5356 17.8995L14.364 20.7279" stroke="white" strokeWidth="2" />
      <path d="M18.6066 10.8284L21.4351 13.6569" stroke="white" strokeWidth="2" />
    </svg>
  );
}
