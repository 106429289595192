import firebase from 'firebase/app';
import 'firebase/firestore';

let _db;

export default function db() {
  if (!_db) {
    _db = firebase.firestore();
    const host = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST;
    if (host) {
      _db.settings({
        host: host,
        ssl: false
      });
    }
  }
  return _db;
}

// https://firebase.google.com/docs/firestore/manage-data/delete-data?hl=ja
export function deleteCollection(db, collectionRef, batchSize=100) {
  let query = collectionRef.orderBy('__name__').limit(batchSize);
  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, query, batchSize, resolve, reject);
  });
}

async function deleteQueryBatch(db, query, batchSize, resolve, reject) {
  try{
    const snapshot = await query.get();
    if(snapshot.size === 0){
      resolve();
      return;
    }
    let batch = db.batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();
    process.nextTick(() => {
      deleteQueryBatch(db, query, batchSize, resolve, reject);
    });
  } catch(e){
    reject(e);
  }
}