import db from "../utilities/db";
import { dialogErrorAction } from "./appAction";
import { redirectDeletedUser, redirectUnauthorizeUser } from "./login";

export async function userExistsIfEmulator(uid) {
  if(process.env.REACT_APP_FIRESTORE_EMULATOR_HOST) {
    const administratorsDoc = db().collection("administrators").doc(uid);
    const admin = await administratorsDoc.get();
    if (!admin.exists) {
      console.log("エミュレータで登録したアカウントでログインしていないため、アカウント削除リスナーの設定を無視します");
      return false;
    }
  }
  return true;
}

export function currentUserListener(dispatch, uid, listeners) {

  try {
    if(listeners.currentUser) return;
    const administratorsDoc = db().collection("administrators").doc(uid);
    const unsub = administratorsDoc.onSnapshot(snapshot => {
      if (!snapshot.exists) {
        // 削除されたら
        redirectDeletedUser(dispatch);
      }
    }, error => {
      if (error && error.name === "FirebaseError" && error.code === "permission-denied") {
        redirectUnauthorizeUser(dispatch);
        return;
      }
      dispatch(dialogErrorAction(error));
    });
    dispatch(listenerAddedAction("currentUser", unsub));
  } catch(e) {
    dispatch(dialogErrorAction(e));
  }
}

export function unsubAllListener(dispatch) {
  dispatch(unsubAllListenerAction());
}

export function removeAllListener(dispatch) {
  dispatch(removeAllListenerAction());
}

function listenerAddedAction(key, unsub) {
  return {
    type: "LISTENER_ADDED",
    payload: {
      key: key,
      unsub: unsub,
    },
  };
}

function unsubAllListenerAction() {
  return {
    type: "UNSUB_ALL_LISTENER",
  };
}

function removeAllListenerAction() {
  return {
    type: "REMOVE_ALL_LISTENER",
  };
}
