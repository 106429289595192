const defaultState = {
  listeners: {
    currentUser: null,
  },
};

const unsubAllListener = (listeners) => {
  if(listeners){
    for (let value of Object.values(listeners)) {
      if (value) value();
    }
  }
}

const listeners = (state = defaultState, action) => {
  switch (action.type) {
    case 'LISTENER_ADDED':
      return {
        ...state,
        listeners: {
          ...state.listeners,
          [action.payload.key]: action.payload.unsub,
        },
      };
    case 'UNSUB_ALL_LISTENER':
      unsubAllListener(state.listeners);
      return state;
    case 'REMOVE_ALL_LISTENER':
      return {
        ...state,
        listeners: {},
      }
    default:
      return state;
  }
};

export default listeners;
