import React from 'react';
import { SvgIcon } from "@material-ui/core";
import DownArrow from './DownArrow';

export default function DownArrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <DownArrow/>
    </SvgIcon>
  )
};