import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Auth from "./auth";
import { withStyles } from "@material-ui/core";
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import * as Actions from './actions/companyAccounts';
import TopBar from "./TopBar";
import ModalWrapper from './ModalWrapper';
import Back from './svg/Back';
import Edit from "./svg/Edit";
import Trash from './svg/Trash';
import DownArrowIcon from './svg/DownArrowIcon';
import { ErrorDialog, InlineError } from "./errorMessage";
import { appRemoveError, inlineError } from "./actions/appAction";

const buttonStyle = {
  height: '32px',
  fontFamily: 'Hiragino Kaku Gothic Pro',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '16px',
  lineHeight: '32px',
  textAlign: 'center',
  borderRadius: '24px',
  borderStyle: 'none',
  outline: 'none',
  cursor: 'pointer',
};

const styles = {
  container: {
    height: 'calc(100vh - 56px - 48px - 80px)',
    margin: '24px',
    padding: '40px',
    background: '#FFFFFF',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
  },
  adminBar: {
    width: '100%',
    height: '32px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    width: '100%',
    height: '32px',
    marginRight: '24px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    color: '#444444',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  backButton: {
    ...buttonStyle,
    minWidth: '140px',
    marginLeft: '8px',
    background: '#AAAAAA',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#CCCCCC',
    },
    '&:disabled': {
      backgroundColor: '#D3D3D3',
      cursor: 'default',
    },
  },
  backIcon: {
    marginLeft: '-14px',
    marginRight: '14px',
  },
  newButton: {
    ...buttonStyle,
    width: '120px',
    height: '32px',
    background: '#FFB72C',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFE456',
    },
    '&:disabled': {
      backgroundColor: '#D3D3D3',
      cursor: 'default',
    },
  },
  applyButton: {
    ...buttonStyle,
    width: '160px',
    height: '48px',
    fontSize: '18px',
    lineHeight: '27px',
    marginLeft: '8px',
    marginRight: '8px',
    color: '#FFFFFF',
    background: '#38496D',
    '&:hover': {
      backgroundColor: '#4360AA',
    },
    '&:disabled': {
      backgroundColor: '#D3D3D3',
      cursor: 'default',
    },
  },
  dismissButton: {
    ...buttonStyle,
    width: '160px',
    height: '48px',
    fontSize: '18px',
    lineHeight: '27px',
    marginLeft: '8px',
    marginRight: '8px',
    background: '#AAAAAA',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#CCCCCC',
    },
    '&:disabled': {
      backgroundColor: '#E0E0E0',
      cursor: 'default',
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "cneter",
    textAlign: "center",
  },
  paper: {
    minWidth: '640px',
    height: 'calc(100vh - 56px - 48px - 80px - 32px - 40px)',
    margin: '40px 0',
    overflowY: 'auto',
  },
  accountGroups: {
  },
  accountTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subtitle: {
    height: '32px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#444444',
  },
  caution: {
    height: '32px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#EE0000',
  },
  table: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'center',
    color: '#AAAAAA',
  },
  tableContainer: {
    boxShadow: 'none',
    marginBottom: '28px',
  },
  tableHead: {
    '& th': {
      border: '0',
    }
  },
  tableBody: {
    '& tr > td:first-child': {
      borderRadius: "8px 0 0 8px",
    },
    '& tr > td:last-child': {
      borderRadius: "0 8px 8px 0",
    },
    '& tr:nth-child(odd)': {
      backgroundColor: "#F9F9F9",
    },
    '& tr:nth-child(even)': {
      backgroundColor: "#FFFFFF",
    },
    '& td': {
      border: '0',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    '& td$uid': {
      textAlign: 'left',
    },
  },
  uid: {
  },
  buttons: {
    display: 'flex',
  },
  button: {
    display: 'block',
    width: '32px',
    height: '32px',
    margin: '0 4px',
    backgroundColor: '#38496D',
    borderRadius: '20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: "#4360AA",
    },
  },
  formControl: {
    minWidth: 'calc(640px - 48px * 2)',
    minHeight: 'calc(480px - 36px * 2 - 42px * 2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalTitle: {
    marginBottom: '32px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '42px',
    textAlign: 'center',
    color: '#444444',
  },
  deleteMessage: {
    width: '540px',
    marginBottom: '18px',
    textAlign: 'center',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '27px',
  },
  input: {
    minWidth: '316px',
    marginBottom: '24px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#444444',
  },
  inputField: {
    height: '40px',
    background: '#EEEEEE',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    '& div.MuiInput-root': {
      height: '100%',
    }
  },
  displayNameInput: {
    marginBottom: '16px',
  },
  labelNameArea: {
    height: '22px',
    maxWidth: '540px',
    lineHeight: '22px',
    marginTop: '8px',
    marginBottom: '34px',
  },
  labelSelection: {
    padding: '4px 2em',
    '& div.MuiSelect-select.MuiSelect-select': {
      color: '#38496d',
      fontSize: '16px',
      lineHeight: '32px',
      padding: '0 24px',
      textAlign: 'center',
    },
    '& .MuiInput': {
      '&:hover': {
        borderBottom: '0px',
        borderColor: 'transparent'
      },
      underline: {
        '&:after': {
          borderBottom: '0px',
          borderColor: 'transparent'
        },
        '&:hover': {
          borderBottom: '0px',
          borderColor: 'transparent'
        }
      }
    }
  },
  buttonArea: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  deleteButtonArea: {
    marginTop: '18px',
  },
  registering: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#444444',
  },
  wordKeepAll: {
    display: 'inline-block',
    textAlign: 'right',
    verticalAlign: 'top',
    maxWidth: 'calc(100% - 20px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wordKeepAllClose: {
    display: 'inline-block',
    textAlign: 'left',
    verticalAlign: 'top',
    width: '20px',
  },
};

const rowsState = {
  rows: [],
  column: null,
  order: null,
};

const testChanged = (rows, prevRows, stateRows) => {
  const columns = rows.length ? Object.keys(rows[0]) : [];
  if (!prevRows
    || rows.length !== stateRows.length
    || rows.length !== prevRows.length
    || rows.some((row, index) => columns.some(col => row[col] !== prevRows[index][col]))) {
    return {
      rows: rows.slice(),
      column: null,
      order: null,
    };
  } else {
    return false;
  }
}

const parseValue = (row, column, isBool) => {
  const v = row[column];
  if (typeof v.toDate === 'function') {
    return v.toDate().getTime();
  } else if (isBool) {
    return v ? 0 : 1;
  } else {
    return v;
  }
}

const sortRows = (state, column, boolColumns) => {
  const sortRule = { asc: [1, -1], desc: [-1, 1] };
  let nextOrder;
  if (!state.order) {
    nextOrder = 'asc';
  } else if (!column) {
    nextOrder = state.order;
  } else if (state.column === column && state.order === 'desc') {
    nextOrder = 'asc';
  } else {
    nextOrder = 'desc';
  }
  const isBool = boolColumns && boolColumns.includes(column);
  //console.log(state);
  //console.log(column + ' ' + nextOrder);
  return {
    rows: state.rows.slice().sort((a, b) => {
      const aVal = parseValue(a, column, isBool);
      const bVal = parseValue(b, column, isBool);
      if (aVal > bVal) {
        return sortRule[nextOrder][0];
      } else if (aVal < bVal) {
        return sortRule[nextOrder][1];
      } else {
        return 0;
      }
    }),
    column: column,
    order: nextOrder,
  };
}

//const loadAccountsValue = (state, key) => state.companyAccounts && state.companyAccounts.companyAccounts && state.companyAccounts.companyAccounts[key];

const createRenderState = state => {
  const order = state.order || 'asc';
  return {
    order: order,
    iconClass: 'MuiTableSortLabel-icon MuiTableSortLabel-iconDirection'
          + order.slice(0, 1).toUpperCase() + order.slice(1),
    column: state.column,
  };
}

const formatDate = timestamp => {
  try {
    const date = timestamp.toDate();
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('/')
      + ' ' + [hours, minutes, seconds].join(':');
  } catch (e) {
    return "";
  }
}

class VguardsClass extends Component {
  state = { ...rowsState }

  componentDidMount() {
    //TODO: ここで強制updateしないと初回時に表示が出ない…バッドノウハウ
    this.setState(rowsState);
  }

  componentDidUpdate(prevProps) {
    const vguards = this.extractVguards(this.props.labels);
    const newState = testChanged(vguards, this.extractVguards(prevProps && prevProps.labels), this.state.rows);
    if (newState) {
      this.setState(newState);
    }
  }

  extractVguards = labels => {
    const vguards = [];
    (labels || []).forEach(label => {
      label.vguards.forEach(item => {
        vguards.push({
          label: label,
          labelName: label.labelName,
          item: item,
          vguard: item.vguard,
          displayName: item.displayName,
          using: item.using,
        });
      });
    });
    return vguards;
  }

  onClickHeadVguards = column => this.setState(sortRows(this.state, column, ['using']));

  render () {
    //console.log(this.props);
    //console.log(this.state);
    const clz = this.props.classes;
    if (!this.props.cid) return null;
    const sortState = createRenderState(this.state);

    return (
      <React.Fragment>
        <div className={clz.accountTitle}>
          <div className={clz.subtitle}>バーチャル警備員アカウント : CID : {this.props.cid}</div>
          <div className={clz.caution} style={{ display: this.props.isMax ? 'block' : 'none' }}>
            契約上限です
          </div>
          <button className={clz.newButton} style={{ display: this.props.isMax ? 'none' : 'block' }} onClick={this.props.onBeginEdit}>
            新規登録
          </button>
        </div>
        <TableContainer component={Paper} className={clz.tableContainer}>
          <Table className={clz.table}>
            <TableHead className={clz.tableHead}>
              <TableRow>
                <TableCell style={{ width: "30%" }}>UID</TableCell>
                <TableCell style={{ width: "25%" }}>
                  <TableSortLabel
                    active={sortState.column === 'labelName'}
                    direction={sortState.order}
                    IconComponent={() => <DownArrowIcon
                      className={sortState.iconClass}
                      width="18"
                      height="18"
                      viewBox="-7 -10 24 24"
                    />}
                    style={{ marginLeft: 26 }}
                    onClick={() => this.onClickHeadVguards('labelName')}>
                    ラベル</TableSortLabel>
                </TableCell>
                <TableCell style={{ width: "25%" }}>
                  <TableSortLabel
                    active={sortState.column === 'displayName'}
                    direction={sortState.order}
                    IconComponent={() => <DownArrowIcon
                      className={sortState.iconClass}
                      width="18"
                      height="18"
                      viewBox="-7 -10 24 24"
                    />}
                    style={{ marginLeft: 26 }}
                    onClick={() => this.onClickHeadVguards('displayName')}>
                    表示名</TableSortLabel>
                </TableCell>
                <TableCell style={{ width: "15%" }}>
                  <TableSortLabel
                    active={sortState.column === 'using'}
                    direction={sortState.order}
                    IconComponent={() => <DownArrowIcon
                      className={sortState.iconClass}
                      width="18"
                      height="18"
                      viewBox="-7 -10 24 24"
                    />}
                    style={{ marginLeft: 26 }}
                    onClick={() => this.onClickHeadVguards('using')}>
                    使用状況</TableSortLabel>
                </TableCell>
                <TableCell style={{ width: "5%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={clz.tableBody}>
              {this.state.rows.map(item => (
                <TableRow key={item.item.vguard}>
                  <TableCell className={clz.uid}>{item.vguard}</TableCell>
                  <TableCell>{item.labelName}</TableCell>
                  <TableCell>{item.displayName}</TableCell>
                  <TableCell>{item.using ? '使用' : '未'}</TableCell>
                  <TableCell>
                    <div className={clz.buttons} >
                      <div className={clz.button} >
                        <Edit onClick={(e) => this.props.onBeginEdit(e, item)} />
                      </div>
                      <div className={clz.button} >
                        <Trash onClick={e => this.props.onDeleteConfirm(e, item.label, item.item)} />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }
}

const Vguards = withStyles(styles)(VguardsClass);

class ToolUsersClass extends Component {
  state = { ...rowsState }

  componentDidMount() {
    //TODO: ここで強制updateしないと初回時に表示が出ない…バッドノウハウ
    this.setState(rowsState);
  }

  componentDidUpdate(prevProps) {
    const users = this.props.toolUsers;
    //console.log(users);
    const newState = testChanged(users, prevProps.toolUsers, this.state.rows);
    if (newState) {
      this.setState(newState);
    }
  }

  onClickHead = column => this.setState(sortRows(this.state, column, ['approval']));

  render() {
    if (!this.props.toolUsers) return null;
    const clz = this.props.classes;
    const sortState = createRenderState(this.state);

    return (
      <React.Fragment>
        <div className={clz.accountTitle}>
          <div className={clz.subtitle}>{this.props.titleLabel}アカウント</div>
        </div>
        <TableContainer component={Paper} className={clz.tableContainer}>
          <Table className={clz.table}>
            <TableHead className={clz.tableHead}>
              <TableRow>
                <TableCell style={{ width: "30%" }}>UID</TableCell>
                <TableCell style={{ width: "25%" }}>
                  <TableSortLabel
                    active={sortState.column === 'displayName'}
                    direction={sortState.order}
                    IconComponent={() => <DownArrowIcon
                      className={sortState.iconClass}
                      width="18"
                      height="18"
                      viewBox="-7 -10 24 24"
                    />}
                    style={{ marginLeft: 26 }}
                    onClick={() => this.onClickHead('displayName')}>
                    担当者名</TableSortLabel>
                </TableCell>
                <TableCell style={{ width: "25%" }}>
                  <TableSortLabel
                    active={sortState.column === 'createdAt'}
                    direction={sortState.order}
                    IconComponent={() => <DownArrowIcon
                      className={sortState.iconClass}
                      width="18"
                      height="18"
                      viewBox="-7 -10 24 24"
                    />}
                    style={{ marginLeft: 26 }}
                    onClick={() => this.onClickHead('createdAt')}>
                    作成日時</TableSortLabel>
                </TableCell>
                <TableCell style={{ width: "15%" }}>
                  <TableSortLabel
                    active={sortState.column === 'approval'}
                    direction={sortState.order}
                    IconComponent={() => <DownArrowIcon
                      className={sortState.iconClass}
                      width="18"
                      height="18"
                      viewBox="-7 -10 24 24"
                    />}
                    style={{ marginLeft: 26 }}
                    onClick={() => this.onClickHead('approval')}>
                    承認</TableSortLabel>
                </TableCell>
                <TableCell style={{ width: "5%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={clz.tableBody}>
              {this.state.rows.map(item => (
                <TableRow key={item.uid}>
                  <TableCell className={clz.uid}>{item.uid}</TableCell>
                  <TableCell>{item.displayName}</TableCell>
                  <TableCell>{formatDate(item.createdAt)}</TableCell>
                  <TableCell>{item.approval ? '済' : '未'}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }
}

const ToolUsers = withStyles(styles)(ToolUsersClass);

class CompanyAccounts extends Component {

  state = {
    executing: false,
    deleteLabel: null,
    deleteItem: null,
  }

  constructor(props) {
    super(props);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
  }

  componentDidMount() {
    // const cid = this.props.match.params && this.props.match.params.cid;
    // const company = this.props.companies.find(c => c.cid === cid);
    // if (company){
    //   Actions.listCompanyAccounts(this.props.dispatch, company);
    // }
    this.updateCompanyAccounts();
  }

  onBeginEdit = (e, row) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    if (!row) {
      // 新規作成
      Actions.beginCreateVguardAccount(this.props.dispatch, null);
    } else {
      // 編集
      const vguardAccount = {
        vguard: row.vguard,
        displayName: row.displayName,
        label: row.label.label,
      };
      Actions.beginCreateVguardAccount(this.props.dispatch, vguardAccount);
    }
  };

  onCloseEdit = (e) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    Actions.cancelCreateVguardAccount(this.props.dispatch);
  };

  onChange = (e) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    Actions.editVguardAccount(this.props.dispatch, e.target.name, e.target.value);
  }

  onUpdateExecute = async (e) =>{
    e.preventDefault();
    if (this.state.executing) return;
    const vguardAccount = this.props.vguardAccount;
    if(vguardAccount.label && vguardAccount.displayName){
      if (!vguardAccount.vguard) {
        // 新規作成時：VG上限チェック
        let label = this.props.companyAccounts.labels.find(x => x.label === vguardAccount.label);
        if(label.length === 0 || label.vguardMaxCount <= label.vguards.length){
          inlineError(this.props.dispatch, "バーチャル警備員上限に達しています");
          return;
        }
      }
      this.setState({
        executing: true,
      });
      await Actions.updateVguardAccount(this.props.dispatch, this.props.companyAccounts.cid, vguardAccount);
      this.setState({
        executing: false,
      });
    } else {
      inlineError(this.props.dispatch, "入力のない必須項目があります");
    }
  }

  onDeleteConfirm = (e, label, item) => {
    e.preventDefault();
    this.setState({
      deleteLabel: label,
      deleteItem: item,
    });
  }

  onDeleteExecute = async (e) => {
    e.preventDefault();
    //console.log(this.state);
    if (!this.state.deleteLabel || !this.state.deleteItem) return;
    this.setState({
      executing: true,
    });
    const success = await Actions.deleteVguardAccount(
      this.props.dispatch,
      this.props.companyAccounts.cid,
      this.state.deleteLabel.label,
      this.state.deleteItem.vguard);
    this.setState({
      executing: false,
    });

    if (!success) return;
    this.setState({
      deleteLabel: null,
      deleteItem: null,
    });
  }

  onCancelDelete = e => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    this.setState({
      deleteLabel: null,
      deleteItem: null,
    });
    this.updateCompanyAccounts();
  }

  onCloseError = (e) => {
    e.preventDefault();
    this.setState({
      deleteLabel: null,
      deleteItem: null,
    });
    Actions.cancelCreateVguardAccount(this.props.dispatch);
    this.updateCompanyAccounts();
    appRemoveError(this.props.dispatch);
  }

  onBackButton = (e) => {
    e.preventDefault();
    this.setState({
      executing: false,
      deleteLabel: null,
      deleteItem: null,
    });
    this.props.history.goBack();
  }

  onClickHead = (key, column) => this.sort(this.props.companyAccounts[key], column);

  updateCompanyAccounts () {
    const cid = this.props.match.params && this.props.match.params.cid;
    const company = this.props.companies.find(c => c.cid === cid);
    if (company){
      Actions.listCompanyAccounts(this.props.dispatch, company);
    }
  }

  render(){
    //console.log(this.props);
    const clz = this.props.classes;
    const companyAccounts = this.props.companyAccounts;
    const isEditVguard = this.props.vguardAccount && !!this.props.vguardAccount.vguard;
    if (!companyAccounts) return (<Auth/>);
    return(
      <React.Fragment>
        <Auth />
        <ErrorDialog onClose={this.onCloseError} />
        <TopBar />
          <div className={clz.container}>
            <div className={clz.adminBar}>
              <div className={clz.title}>アカウント管理 - {companyAccounts.companyName}</div>
              <button className={clz.backButton} onClick={this.onBackButton}>
                <Back className={clz.backIcon} />
                一覧へ戻る
              </button>
            </div>
            <div className={clz.paper}>
              <div className={clz.accountGroups}>
                <Vguards
                  labels={this.props.companyAccounts.labels}
                  cid={this.props.companyAccounts.cid}
                  isMax={this.props.companyAccounts.isAllVguardMax}
                  onBeginEdit={this.onBeginEdit}
                  onDeleteConfirm={this.onDeleteConfirm} />
                <ToolUsers
                  titleLabel="監視ツール管理者"
                  toolUsers={this.props.companyAccounts.managers} />
                <ToolUsers
                  titleLabel="監視ツール利用者"
                  toolUsers={this.props.companyAccounts.users} />
              </div>
            </div>
          </div>
          <ModalWrapper
            open={!!this.props.vguardAccount}
            onClose={this.onCloseEdit}
            content={
              <div className={clz.formControl}>
                <div className={clz.modalTitle}>バーチャル警備員アカウント管理<br />{isEditVguard ? '編集' : '新規登録'}</div>
                  {this.props.vguardAccount && this.props.vguardAccount.vguard ?
                    <div className={clz.input + ' ' + clz.labelNameArea} >
                      ラベル名：
                      <div className={clz.wordKeepAll} style={{ maxWidth: 'calc(540px - 80px)' }}>
                        {companyAccounts.labels && companyAccounts.labels.find(label => (
                          label.label === this.props.vguardAccount.label
                        )).labelName}
                      </div>
                    </div>
                  :
                    <div className={clz.input}>
                      <Select
                        className={clz.labelSelection + ' ' + clz.inputField}
                        name="label"
                        value={(this.props.vguardAccount && this.props.vguardAccount.label) || ''}
                        onChange={this.onChange}
                        disableUnderline
                        IconComponent={() => <DownArrowIcon className="MuiSelect-icon" viewBox="0 -10 24 24" />}>
                        {companyAccounts.labels && companyAccounts.labels.map(label => {
                          return (
                            <MenuItem key={label.label} value={label.label}>{label.labelName}</MenuItem>
                          )
                        })}
                      </Select>
                    </div>
                  }
                <div className={clz.input + ' ' + clz.displayNameInput}>
                  <TextField
                    className={clz.inputField}
                    name="displayName"
                    value={(this.props.vguardAccount && this.props.vguardAccount.displayName) || ''}
                    placeholder="表示名入力"
                    InputProps={{ disableUnderline: true }}
                    inputProps={{
                      style: {
                        textAlign: 'center',
                        padding: '0 22px',
                        fontSize: '16px',
                        height: '40px',
                        lineHeight: '19px',
                        borderRadius: '20px',
                      }
                    }}
                    onChange={this.onChange}/>
                </div>
                <InlineError />
                <div className={clz.input + ' ' + clz.buttonArea}>
                  <button className={clz.applyButton} onClick={this.onUpdateExecute}>{isEditVguard ? '更新' : '登録'}する</button>
                  {isEditVguard &&
                    <button className={clz.dismissButton} onClick={this.onCloseEdit}>キャンセル</button>
                  }
                </div>
              </div>
            }/>
          <ModalWrapper
            open={!!this.state.deleteItem}
            onClose={this.onCancelDelete}
            content={
              <div>
                <div className={clz.formControl}>
                  <div className={clz.deleteMessage}>
                    <div className={clz.wordKeepAll}>「{this.state.deleteItem && this.state.deleteItem.displayName}</div>
                    <div className={clz.wordKeepAllClose}>」</div>
                    を削除しますか？
                  </div>
                  <InlineError />
                  <div className={clz.buttonArea + ' ' + clz.deleteButtonArea}>
                    <div>
                      <button className={clz.applyButton} onClick={this.onDeleteExecute}>削除する</button>
                    </div>
                    <div>
                      <button className={clz.dismissButton} onClick={this.onCancelDelete}>キャンセル</button>
                    </div>
                  </div>
                </div>
              </div>
            }/>
          <ModalWrapper
            open={!this.state.deleteItem && (this.props.isListen || this.state.executing)}
            noCloseButton={true}
            noMinSizing={true}
            content={
             <p className={clz.registering}>登録中…</p>
            }/>
          <ModalWrapper
            open={this.state.deleteItem && this.state.executing}
            noCloseButton={true}
            noMinSizing={true}
            content={
             <p className={clz.registering}>削除中…</p>
            }/>
        </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(
  connect(state => {
    //console.log(state);
    return {
      user: state.user && state.user.user,
      companies: state.companies && state.companies.companies,
      companyAccounts: state.companyAccounts && state.companyAccounts.companyAccounts,
      vguardAccount: state.companyAccounts && state.companyAccounts.vguardAccount,
      isListen: state.companyAccounts && state.companyAccounts.isListen,
    };
  })(CompanyAccounts)
));
