import firebase from 'firebase/app';
import 'firebase/auth';

export default class AuthorizerFirebase {

  constructor() {
    this._auth = null;
  }

  auth() {
    if (!this.firestore) {
      this._auth = firebase.auth();
    }
    return this._auth;
  }

  async authorize(email, password) {
    await this.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    return this.auth().signInWithEmailAndPassword(email, password).catch(e => undefined);
  }

  async getUser(authorized) {
    if (!authorized || !authorized.user) {
      return null;
    }
    const token = await authorized.user.getIdTokenResult();
    if (!token || !token.claims || token.claims.role !== 'administrator') {
      return null;
    }
    return {
      uid: authorized.user.uid,
      email: authorized.user.email,
      displayName: authorized.user.displayName,
      role: token.claims.role
    };
  }

  async getCurrentUser() {
    return this.auth().currentUser;
  }

  async isAuthorized() {
    return new Promise((resolve, reject) => {
      const unsub = this.auth().onAuthStateChanged((user) => {
        unsub();
        resolve(!!user);
      }, error => {
        reject(error);
      });
    });
  }

  async unauthorize() {
    return this.auth().signOut();
  }
}
