import React from 'react';

export default function Account(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16" />
      <path d="M16 6C14.82 6 13.75 6.43 12.89 7.11C12.6 7.34 12.34 7.6 12.11 7.88C11.42 8.74 11 9.82 11 11C11 12.49 11.66 13.81 12.69 14.72C9.93 15.98 8 18.76 8 22C8 23.1 8.9 24 10 24H22C23.1 24 24 23.1 24 22C24 18.76 22.07 15.98 19.31 14.72C19.7 14.38 20.03 13.98 20.3 13.53C20.74 12.79 21 11.93 21 11C21 8.24 18.76 6 16 6ZM16 14C14.35 14 13 12.65 13 11C13 9.35 14.35 8 16 8C17.65 8 19 9.35 19 11C19 12.65 17.65 14 16 14ZM10 22C10 21.31 10.12 20.65 10.34 20.03C11.15 17.69 13.38 16 16 16C18.62 16 20.85 17.69 21.66 20.03C21.88 20.65 22 21.31 22 22H10Z" fill="white" />
    </svg>
  );
}
