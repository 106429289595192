import functions, { isFunctionsSdkInternalError, isTokenRevokedError } from '../utilities/functions';
import db from '../utilities/db';
import { inlineErrorAction, dialogErrorAction, offLineErrorAction, libraryErrorAction } from './appAction';
import { reLoginCurrentUser, redirectUnauthorizeUser, isValidPasswordCurrentUser } from './login';

export async function listAdminUsers(dispatch) {
  try {
    const adminUsers = await (functions().httpsCallable('listAdminUsers'))();
    dispatch(adminUsersAction(adminUsers.data));
  } catch (e) {
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("アドミニストレーター一覧取得"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(inlineErrorAction(e));
  }
}

export async function beginEditAdminUser(dispatch, uid, onlyPassword) {
  dispatch(beginEditAdminUserAction(uid, onlyPassword));
}

export async function editAdminUser(dispatch, user) {
  dispatch(editAdminUserAction(user));
}

export async function updateAdminUser(dispatch, user) {
  const functionName = user.uid ? 'updateAdminUser' : 'createAdminUser';
  try {
    if (user.uid) {
      const admin = await db().collection('administrators').doc(user.uid).get();
      if (!admin.exists) {
        dispatch(dialogErrorAction("このアドミニストレーターアカウントは既に削除されています。"));
        return;
      }
      const isPasswordOnlyUpdate = !user.displayName && !user.email;
      if (isPasswordOnlyUpdate) {
        // パスワード変更フォームの時：PW検証
        if (!(await isValidPasswordCurrentUser(user.uid, user.currentPassword))) {
          dispatch(inlineErrorAction("現在のパスワードに誤りがあります。"));
          return;
        }
      }
    }
    const adminUser = await (functions().httpsCallable(functionName))(user);
    await reLoginCurrentUser(dispatch, adminUser, user.password);
    dispatch(updateAdminUserAction(adminUser));
    //dispatch({ type: 'CANCEL_EDIT_ADMIN_USER' });
    return true;
  } catch (e) {
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch);
      return;
    }
    if (e.message === "uid does not match") {
      dispatch(inlineErrorAction("現在ログインしているアカウントとUIDが異なるため更新できません"));
      return;
    }
    if (e.details && e.details.code === "auth/invalid-email") {
      dispatch(inlineErrorAction("このEmailは登録できない形式です。"));
      return;
    }
    if (!navigator.onLine || (e.name === 'FirebaseError' && e.code === 'unavailable')) {
      dispatch(offLineErrorAction("アドミニストレーター" + (user.uid ? '更新' : '登録')));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(inlineErrorAction("このEmailは登録済みです"));
  }
}

export async function beginDeleteAdminUser(dispatch, uid) {
    dispatch(beginDeleteAdminUserAction(uid));
}

export async function deleteAdminUser(dispatch, uid) {
  try {
    const admin = await db().collection('administrators').doc(uid).get();
    if (!admin.exists) {
      dispatch(dialogErrorAction("このアドミニストレーターアカウントは既に削除されています。", true));
      return;
    }
    await (functions().httpsCallable('deleteAdminUser'))({ uid: uid });
    dispatch(deleteAdminUserAction(uid));
  } catch (e) {
    if (isTokenRevokedError(e)) {
      cancelDeleteAdminUser(dispatch); // 再ログイン後削除確認ダイアログが出たままになってしまうので、キャンセル
      redirectUnauthorizeUser(dispatch);
      return;
    }
    if (!navigator.onLine || (e.name === 'FirebaseError' && e.code === 'unavailable')) {
      dispatch(offLineErrorAction("アドミニストレーター削除"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(inlineErrorAction(e));
  }
}

export async function cancelEditAdminUser(dispatch) {
  dispatch({ type: 'CANCEL_EDIT_ADMIN_USER'});
}

export async function cancelDeleteAdminUser(dispatch) {
  dispatch({ type: 'CANCEL_DELETE_ADMIN_USER'});
}

export function searchAdmin(dispatch, search){
  dispatch({
    type: "ADMIN_SEARCH",
    payload: {
      search: search,
    }
  });
}

function adminUsersAction(adminUsers) {
  return {
    type: 'ADMIN_USERS',
    payload: {
      adminUsers: adminUsers
    }
  };
}

function beginEditAdminUserAction(uid, onlyPassword) {
  return {
    type: onlyPassword ? 'BEGIN_EDIT_PASSWORD_ADMIN_USER' : 'BEGIN_EDIT_ADMIN_USER',
    payload: {
      uid: uid
    }
  };
}

function editAdminUserAction(editingUser) {
  return {
    type: "EDIT_ADMIN_USER",
    payload: {
      editingUser: editingUser
    }
  };
}

function updateAdminUserAction(editingUser) {
  return {
    type: "UPDATE_ADMIN_USER",
    payload: {
      editingUser: editingUser
    }
  };
}

function beginDeleteAdminUserAction(uid) {
  return {
    type: "BEGIN_DELETE_ADMIN_USER",
    payload: {
      uid: uid
    }
  };
}

function deleteAdminUserAction(uid) {
  return {
    type: "DELETE_ADMIN_USER",
    payload: {
      uid: uid
    }
  };
}
