import functions, { isTokenRevokedError } from '../utilities/functions';
import authorizerFB from './authorizerFirebase';
import { clearStateWithLogoutAction } from '.';
import { removeAllListener, unsubAllListener } from './listeners';

const authorizer = new authorizerFB();

export async function setUserError(dispatch, message) {
  dispatch(setUserErrorAction(message));
}

export async function login(dispatch, email, password) {
  dispatch(setUserErrorAction(null));
  try {
    const authorized = await authorizer.authorize(email, password);
    if (!authorized) {
      return dispatch(authorizationFailed());
    }
    const user = await authorizer.getUser(authorized);
    if (!user) {
      return dispatch(noRole(''));
    }
    sessionStorage.setItem("admin_user", JSON.stringify(user));
    dispatch(updateUser(user));
  } catch (e) {
    dispatch(failed(e));
  }
}

export async function isValidPasswordCurrentUser(uid, password) {
  // 自身のパスワードの検証（PW変更時に確認する）
  // 他人のパスワードは検証できない（確認中:VGUARD-555）
  const currentUser = await authorizer.getCurrentUser();
  if (currentUser.uid !== uid) {
    // ログイン中のユーザではない場合検証できない
    return false;
  }
  const authorized = await authorizer.authorize(currentUser.email, password);
  if (!authorized) return false;
  return true;
}

export async function reLoginCurrentUser(dispatch, editUser, newPassword) {
  // パスワード変更時などに再認証する
  const currentUser = await authorizer.getCurrentUser();
  if (!editUser || !editUser.data) return;
  if (currentUser.uid !== editUser.data.uid) {
    // ログイン中のユーザではない
    return;
  }
  await login(dispatch, editUser.data.email, newPassword);
}

export async function sessionLogin(dispatch, user) {
  const isAuthorized = await authorizer.isAuthorized();
  if (!isAuthorized) {
    redirectUnauthorizeUser(dispatch);
    return;
  }
  dispatch(updateUser(user));
}

function setUserErrorAction(message) {
  return {
    type: 'FAILED',
    error: message,
  }
}

function updateUser(user) {
  return {
    type: 'AUTHORIZED',
    payload: {
      user: user
    }
  }
}

export async function logout(dispatch, user) {
  await signOut(dispatch, unauthorize(), user.uid);
}

export async function redirectDeletedUser(dispatch) {
  await signOut(dispatch, accountDeleted());
}

export async function redirectUnauthorizeUser(dispatch) {
  await signOut(dispatch, unauthorizeUser());
}

async function signOut(dispatch, action, revokeTokenUid = null) {
  unsubAllListener(dispatch);

  if (revokeTokenUid) {
    try {
      await (functions().httpsCallable('signOutRevokeRefreshToken'))({ uid: revokeTokenUid });
    } catch (e) {
      if (isTokenRevokedError(e)) {
        // 既にトークン無効化済み
      } else {
        console.warn('トークンの無効化に失敗した可能性があります');
      }
    }
  }
  sessionStorage.removeItem("admin_user");
  dispatch(action);
  await authorizer.unauthorize();
  removeAllListener(dispatch);
  dispatch(clearStateWithLogoutAction());
}

function authorizationFailed() {
  return {
    type: 'AUTHORIZATION_FAILED'
  }
}

function noRole(role) {
  return {
    type: 'NO_ROLE',
    role: role
  }
}

function failed(e) {
  return {
    type: 'FAILED',
    error: e
  }
}

function unauthorize() {
  return {
    type: 'UNAUTHORIZED'
  }
}

function accountDeleted() {
  return {
    type: 'ACCOUNT_DELETED'
  }
}

function unauthorizeUser() {
  return {
    type: 'UNAUTHORIZED_USER'
  }
}
