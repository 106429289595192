import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Auth from './auth';
import { logout } from './actions/login';
import { listCompanies, doSortChange, checkData } from './actions/companies';
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core";
import TopBar from './TopBar';
import AdminBar from './AdminBar';
import Edit from './svg/Edit';
import Account from './svg/Account';
import ModalWrapper from './ModalWrapper';
import CompanyForm from './companyForm';
import { editCompany } from './actions/companies';
import DownArrowIcon from './svg/DownArrowIcon';
import DataRepair from './svg/DataRepair';
import { currentUserListener, userExistsIfEmulator } from './actions/listeners';

export class Main extends Component {
  state = {
    companies: [],
    currentSortColumn: null,
    currentSortOrder: null,
    companyForm: false,
    selectedCid: null,
    selectedColumn: null,
    checkDataExecuting: false,
    isOpenCheckDataConfirm: false,
    checkDataCid: null,
  };

  DEFAULT_SORT_COLUMN = 'createdAt';

  async componentDidMount() {
    // console.log("componentDidMount", this.props.user);
    if (!this.props.user) return;
    await listCompanies(this.props.dispatch, [], 10000);
  }

  async componentDidUpdate() {
    // console.log("componentDidUpdate", this.props);
    if(this.props.user && this.props.isFirstGetList){
      await listCompanies(this.props.dispatch, [], 10000);
    }
    if (this.props.doSort === true) {
      doSortChange(this.props.dispatch, false);
      this.sort(this.props.searchCompanies);
    }
    if (this.props.user && !this.props.listeners.currentUser) {
      if (await userExistsIfEmulator(this.props.user.uid)) {
        // エミュレータの場合エミュレータで作成したアカウント以外ではリスナーセットしない
        currentUserListener(this.props.dispatch, this.props.user.uid, this.props.listeners);
      }
    }
  }

  onLogout = (e) => {
    e.preventDefault();
    logout(this.props.dispatch);
  }

  formatDate = (timestamp) => {
    try{
      const date = timestamp.toDate();
      return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('/')
        + '\n'
        + [date.getHours(), date.getMinutes(), date.getSeconds()]
        .map(v => (v < 10 ? '0' + v : v + ''))
        .join(':');
    } catch(e){
      return "";
    }
  }

  onCompanyForm = (e, cid) => {
    e.preventDefault();
    editCompany(this.props.dispatch, this.props.companies.find(c => c.cid === cid));
    this.setState({
      companyForm: true
    });
  }

  onNewCompanyForm = () => {
    editCompany(this.props.dispatch);
    this.setState({
      companyForm: true
    });
  }

  onAdminForm = (e) => {
    e.preventDefault();
    this.props.history.push('adminForm');
  }

  onAccountManageClick = (e, cid) => {
    e.preventDefault();
    this.props.history.push('/accounts/' + cid);
  }

  onPasswordForm = (e) => {
    e.preventDefault();
    this.props.history.push('/password');
  }

  onCloseCompanyForm = () => {
    this.setState({
      companyForm: false
    });
  }

  sort = (companies, column) => {
    const sortRule = { asc: [1, -1], desc: [-1, 1] };
    let nextOrder;
    if (!this.state.currentSortOrder) {
      nextOrder = 'desc';
    } else if (!column) {
      nextOrder = this.state.currentSortOrder;
    } else if (this.state.currentSortColumn === column && this.state.currentSortOrder === 'desc') {
      nextOrder = 'asc';
    } else {
      nextOrder = 'desc';
    }
    column = column || this.DEFAULT_SORT_COLUMN;
    this.setState({
      companies: companies.slice().sort((a, b) => {
        const aVal = a[column].toDate().getTime();
        const bVal = b[column].toDate().getTime();
        if (aVal > bVal) {
          return sortRule[nextOrder][0];
        } else if (aVal < bVal) {
          return sortRule[nextOrder][1];
        } else {
          return 0;
        }
      }),
      currentSortColumn: column,
      currentSortOrder: nextOrder,
    });
  }

  onClickHead = (column) => this.sort(this.props.searchCompanies, column);

  onCheckDataConfirm = (e, cid) => {
    e.preventDefault();
    this.setState({
      isOpenCheckDataConfirm: true,
      checkDataCid: cid,
    });
  }

  onCheckData = async (e, isUpdate=false) => {
    if(!this.state.checkDataCid) return;
    const cid = this.state.checkDataCid;
    e.preventDefault();
    this.setState({
      isOpenCheckDataConfirm: false,
      checkDataExecuting: true,
    }, async () => {
      await checkData(this.props.dispatch, cid, isUpdate);
      this.setState({
        checkDataExecuting: false,
        checkDataCid: null,
      })
    });
  }

  onCloseCheckData = () => {
    this.setState({
      isOpenCheckDataConfirm: false
    });
  }

  render() {
    const sortOrder = (this.state.currentSortOrder || 'desc')
    const sortIconClass = 'MuiTableSortLabel-icon MuiTableSortLabel-iconDirection'
      + sortOrder.slice(0, 1).toUpperCase() + sortOrder.slice(1);
    const clz = this.props.classes;
    return (
      <div>
        <Auth />
        <TopBar />
        <div className={clz.container}>
          <AdminBar
            myState="main"
            newButtonIsEnabled={true}
            searchBarIsEnabled={true}
            onNew={this.onNewCompanyForm}
            searchText={this.props.search}
          />
          <TableContainer className={clz.table}>
            <Table aria-label="simple table">
              {/*<caption style={{ color: "red" }}>{this.props.error}</caption>*/}
              <TableHead>
                <TableRow className={clz.header}>
                  <TableCell>会社名</TableCell>
                  <TableCell>担当者名</TableCell>
                  <TableCell>電話番号</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={this.state.currentSortColumn === 'createdAt'}
                      direction={sortOrder}
                      IconComponent={() => <DownArrowIcon
                        className={sortIconClass}
                        width="18"
                        height="18"
                        viewBox="-7 -10 24 24"
                      />}
                      onClick={() => this.onClickHead('createdAt', true)}>
                      作成日時
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={this.state.currentSortColumn === 'updatedAt'}
                      direction={sortOrder}
                      IconComponent={() => <DownArrowIcon
                        className={sortIconClass}
                        width="18"
                        height="18"
                        viewBox="-7 -10 24 24"
                        />}
                      onClick={() => this.onClickHead('updatedAt', true)}>
                      更新日時
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>契約<br/>台数</TableCell>
                  <TableCell>稼働<br/>台数</TableCell>
                  <TableCell>アカウント<br/>有効化</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.companies.map((row, index) => (
                  <TableRow key={row.cid} className={clz[index % 2 ? 'oddRow' : 'evenRow']}>
                    <TableCell>{row.companyName}</TableCell>
                    <TableCell className={clz.centerCell}>{row.companyContactName}</TableCell>
                    <TableCell className={clz.centerCell}>{row.companyContactTel}</TableCell>
                    <TableCell className={clz.centerCell + ' ' + clz.smallCell + ' ' + clz.smallWidthCell}>{row.companyContactMails ? row.companyContactMails[0] : ""}</TableCell>
                    <TableCell className={clz.twoLinesSmallCell}>{this.formatDate(row.createdAt)}</TableCell>
                    <TableCell className={clz.twoLinesSmallCell}>{this.formatDate(row.updatedAt)}</TableCell>
                    <TableCell className={clz.centerCell + ' ' + clz.smallCell + ' ' + clz.smallWidthCell}>{(row.labels || []).reduce((acc, label) => acc + label.vguardMaxCount, 0)}</TableCell>
                    <TableCell className={clz.centerCell + ' ' + clz.smallCell + ' ' + clz.smallWidthCell}>{(row.labels || []).reduce((acc, label) => acc + label.vguardCount, 0)}</TableCell>
                    <TableCell className={clz.centerCell + ' ' + clz.smallCell + ' ' + clz.mediumWidthCell}>{row.managerAccountEnabled ? '済' : '未'}</TableCell>
                    <TableCell>
                      <div className={clz.buttons}>
                        <div className={clz.button}>
                          <Edit onClick={(e) => this.onCompanyForm(e, row.cid)} />
                        </div>
                        <div className={clz.button}>
                          <Account onClick={(e) => this.onAccountManageClick(e, row.cid)} />
                        </div>
                        { (process.env.REACT_APP_ENV === "test" || process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "test2" || process.env.REACT_APP_ENV === "test3") &&
                          <div className={clz.buttonRed}>
                            <DataRepair onClick={(e) => this.onCheckDataConfirm(e, row.cid)} />
                          </div>
                        }
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ModalWrapper
            open={this.state.companyForm}
            onClose={this.onCloseCompanyForm}
            content={<CompanyForm onClose={this.onCloseCompanyForm} />}
          />
          <ModalWrapper
            open={this.state.isOpenCheckDataConfirm}
            onClose={this.onCloseCheckData}
            content={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "100%" }}>
                  <p className={clz.popupContentText} style={{ textAlign: "center", marginBottom: 36 }}>データを修復しますか？</p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <button className={clz.applyButton} onClick={(e) => this.onCheckData(e, false)}>ログ出力のみ</button>
                    </div>
                    { (process.env.REACT_APP_ENV === "test" || process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "test2" || process.env.REACT_APP_ENV === "test3") &&
                      <div>
                        <button className={clz.repairButton} onClick={(e) => this.onCheckData(e, true)}>修復する</button>
                      </div>
                    }
                    <div>
                      <button className={clz.dismissButton} onClick={this.onCloseCheckData}>キャンセル</button>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
          <ModalWrapper
            open={this.state.checkDataExecuting}
            noCloseButton={true}
            noMinSizing={true}
            content={
              <p className={clz.registering}>実行中…</p>
          } />
        </div>
      </div>
    );
  }
}

const buttonStyle = {
  width: '160px',
  height: '48px',
  borderRadius: '24px',
  fontFamily: 'Hiragino Kaku Gothic Pro',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '18px',
  lineHeight: '48px',
  textAlign: 'center',
  borderStyle: 'none',
  outline: 'none',
  cursor: 'pointer',
};

const styles = {
  container: {
    height: 'calc(100vh - 56px - 48px - 80px)',
    minWidth: 'calc(1024px - 80px - 48px)',
    margin: '24px',
    padding: '40px',
    background: '#FFFFFF',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
  },
  table: {
    height: 'calc(100vh - 56px - 48px - 80px - 32px)',
    margin: '12px 0',
    '& tr': {
      '& > th': {
        borderBottom: '0',
        lineHeight: '14px',
      },
      '& > td': {
        borderBottom: '0',
        lineHeight: '15px',
      },
      '& > td:first-child': {
        borderRadius: '8px 0 0 8px',
      },
      '& > td:last-child': {
        borderRadius: '0 8px 8px 0',
      },
    },
  },
  header: {
    background: '#FFFFFF'
  },
  oddRow: {
    background: '#FFFFFF',
  },
  evenRow: {
    background: '#F9F9F9',
  },
  centerCell: {
    textAlign: 'center'
  },
  smallCell: {
    fontSize: '10px',
  },
  smallWidthCell: {
    minWidth: '40px',
  },
  mediumWidthCell: {
    minWidth: '65px',
  },
  twoLinesSmallCell: {
    fontSize: '10px',
    lineHeight: '12px',
    whiteSpace: 'pre-line',
    minWidth: '80px',
  },
  buttons: {
    display: 'flex'
  },
  button: {
    width: '32px',
    height: '32px',
    margin: '0 4px',
    backgroundColor: '#38496D',
    borderRadius: '20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: "#4360AA",
    },
  },
  buttonRed: {
    width: '32px',
    height: '32px',
    margin: '0 4px',
    backgroundColor: '#E14B43',
    borderRadius: '20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: "#F16B63",
    },
  },
  registering: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#444444',
  },
  applyButton: {
    ...buttonStyle,
    marginLeft: '8px',
    marginRight: '8px',
    color: '#FFFFFF',
    background: '#38496D',
    '&:hover': {
      backgroundColor: '#4360AA',
    },
    '&:disabled': {
      backgroundColor: '#D3D3D3',
      cursor: "default",
    },
  },
  dismissButton: {
    ...buttonStyle,
    marginLeft: '8px',
    marginRight: '8px',
    background: '#AAAAAA',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#CCCCCC',
    },
    '&:disabled': {
      backgroundColor: '#E0E0E0',
      cursor: "default",
    },
  },
  repairButton: {
    ...buttonStyle,
    marginLeft: '8px',
    marginRight: '8px',
    color: '#FFFFFF',
    background: '#E14B43',
    '&:hover': {
      backgroundColor: '#F16B63',
    },
    '&:disabled': {
      backgroundColor: '#D3D3D3',
      cursor: "default",
    },
  },

  popupContentText: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: "27px",
  }
};

export default withRouter(withStyles(styles)(connect(state => ({
  user: state.user && state.user.user,
  companies: (state.companies && state.companies.companies) || [],
  doSort: state.companies && state.companies.doSort,
  error: state.companies && state.companies.error,
  search: state.companies && state.companies.search,
  searchCompanies: (state.companies && state.companies.searchCompanies) || [],
  isFirstGetList: state.companies && state.companies.isFirstGetList,
  listeners: state.listeners && state.listeners.listeners,
}))(Main)));
