const defaultState = {
  companies: [],
  selectedCompany: null,
  doSort: false,
  search: "",
  searchCompanies: [],
  isFirstGetList: true,
};

function defaultLabelState() {
  return {
    label: null,
    labelName: '',
    vguardMaxCount: 3,
    vguardCount: 0,
    createdAt: null,
    doSort: false,
  };
}

function defaultCompanyState() {
  return {
    cid: null,
    companyName: '',
    companyContactName: '',
    companyContactDepartment: '',
    companyContactTel: '',
    companyContactMails: [''],
    automaticApprovalMails: [''],
    createdAt: null,
    updatedAt: null,
    managerAccountEnabled: false,
    companyDescription: '',
    labels: [defaultLabelState()]
  };
}

function duplicateCompany(company) {
  company = { ...company };
  company.companyContactMails = company.companyContactMails.slice();
  company.automaticApprovalMails = company.automaticApprovalMails.slice();
  company.labels = company.labels.map(label => { return { ...label } });
  return company;
}

function duplicateActives(actives) {
  return { ...actives };
}

function search(companies, search) {
  if (search) {
    companies = companies.filter(x => x.companyName.indexOf(search) >= 0);
  }
  return companies;
}

const companies = (state = defaultState, action) => {
  let companies, searchCompanies;
  switch (action.type) {
    case 'COMPANIES' :
      // searchCompanies = action.payload.companies;
      // if (state.search) {
      //   searchCompanies = searchCompanies.filter(x => x.companyName.indexOf(state.search) >= 0);
      // }
      searchCompanies = search(action.payload.companies, state.search);
      return {
        companies: action.payload.companies,
        search: state.search,
        searchCompanies: searchCompanies,
        doSort: true,
        isFirstGetList: false,
      };
    case 'COMPANY_SEARCH' :
      // searchCompanies = state.companies;
      // if (action.payload.search) {
      //   searchCompanies = searchCompanies.filter(x => x.companyName.indexOf(action.payload.search) >= 0);
      // }
      searchCompanies = search(state.companies, action.payload.search);
      return {
        ...state,
        search: action.payload.search,
        searchCompanies: searchCompanies,
        doSort: true,
      };
    case "COMPANIES_DO_SORT_CHANGE":
      return {
        ...state,
        doSort: action.doSort,
      };
    case 'ADD_COMPANY' :
      return {
        ...state,
        selectedCompany: defaultCompanyState(),
      }
    case 'ADD_LABEL':
      return {
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          labels: state.selectedCompany.labels.concat(defaultLabelState())
        },
      }
    case 'DELETE_LABEL':
      return {
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          labels: state.selectedCompany.labels.slice(0, action.payload.deletingLabelIndex).concat(
            state.selectedCompany.labels.slice(action.payload.deletingLabelIndex + 1)
          )
        }
      }
    case 'DEFAULT_LABEL':
      return {
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          labels: [defaultLabelState()],
        }
      }
    case 'EDIT_COMPANY':
      return {
        ...state,
        selectedCompany: duplicateCompany(action.payload.selectedCompany),
        actives: duplicateActives(action.payload.actives),
      }
    case 'UPDATE_COMPANY':
      companies = (() => {
        const companies = state.companies.slice();
        const index = companies.findIndex(c => c.cid === action.payload.selectedCompany.cid);
        if (index >= 0) {
          companies[index] = action.payload.selectedCompany;
        } else {
          companies.unshift(action.payload.selectedCompany);
        }
        return companies;
      })();
      searchCompanies = search(companies, state.search);
      return {
        ...state,
        companies: companies,
        searchCompanies: searchCompanies,
        selectedCompany: null,
        doSort: true,
      }
    default:
      return state;
  }
};

export default companies;
