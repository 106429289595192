import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Auth from './auth';
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core";
import * as Actions from './actions/admin';
import TopBar from './TopBar';
import AdminBar from './AdminBar';
import Edit from './svg/Edit';
import Trash from './svg/Trash';
import ModalWrapper from './ModalWrapper';
import { ErrorDialog, InlineError } from "./errorMessage";
import { appRemoveError, inlineError } from './actions/appAction';

const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export class AdminForm extends Component {
  state = {
    executing: false,
  }

  componentDidMount() {
    //passwordFormから遷移した場合に一瞬出てしまうのをキャンセル
    Actions.cancelEditAdminUser(this.props.dispatch);
    Actions.listAdminUsers(this.props.dispatch);
    appRemoveError(this.props.dispatch);
  }

  componentDidUpdate() {
    if (this.state.executing) {
      if (!this.props.editingUser && !this.props.deletingUser) {
        this.setState({
          executing: false,
        });
      }
    }
  }

  formatDate(timestamp) {
    const date = timestamp.toDate();
    return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('/')
     + ' ' + [date.getHours(), date.getMinutes(), date.getSeconds()].join(':');
  }

  onChange = (e) => {
    const editingUser = { ...this.props.editingUser };
    editingUser[e.target.name] = e.target.value;
    Actions.editAdminUser(this.props.dispatch, editingUser);
    appRemoveError(this.props.dispatch);
  }

  onBeginEdit = (e, uid) =>{
    e.preventDefault();
    Actions.beginEditAdminUser(this.props.dispatch, uid);
    appRemoveError(this.props.dispatch);
  }

  onEndEdit = async (e, save) => {
    e.preventDefault();
    if (this.state.executing) return;
    if (save) {
      //TODO: validator
      const user = this.props.editingUser;
      if (!user.displayName) {
        inlineError(this.props.dispatch, '名前を指定してください');
        return;
      }
      if (!user.email) {
        inlineError(this.props.dispatch, 'Emailを指定してください');
        return;
      }
      if (!user.uid && !user.password) {
        inlineError(this.props.dispatch, 'パスワードを指定してください');
        return;
      }
      if (!user.uid && (user.password !== user.passwordConfirm)) {
        inlineError(this.props.dispatch, 'パスワードが一致しません');
        return;
      }
      if (!regex.test(user.email) || (user.password && user.password.length < 6)) {
        inlineError(this.props.dispatch, '');
        return;
      }
      this.setState({
        executing: true,
      });
      await Actions.updateAdminUser(this.props.dispatch, user);
      this.setState({
        executing: false,
      });
    } else {
      Actions.cancelEditAdminUser(this.props.dispatch);
      Actions.listAdminUsers(this.props.dispatch);
      appRemoveError(this.props.dispatch);
    }
  }

  onBeginDelete = (e, uid) => {
    e.preventDefault();
    Actions.beginDeleteAdminUser(this.props.dispatch, uid);
    appRemoveError(this.props.dispatch);
  }

  onDelete = async (e) => {
    e.preventDefault();
    if (this.state.executing) return;

    this.setState({
      executing: true,
    });
    await Actions.deleteAdminUser(this.props.dispatch, this.props.deletingUser.uid);
    this.setState({
      executing: false,
    });
  }

  onCancelDelete = () => {
    if (this.state.executing) return;
    Actions.cancelDeleteAdminUser(this.props.dispatch);
    Actions.listAdminUsers(this.props.dispatch);
    appRemoveError(this.props.dispatch);
  }

  onCloseError = (e) => {
    e.preventDefault();
    Actions.cancelEditAdminUser(this.props.dispatch);
    Actions.cancelDeleteAdminUser(this.props.dispatch);
    Actions.listAdminUsers(this.props.dispatch);
    appRemoveError(this.props.dispatch);
  }

  render() {
    const clz = this.props.classes;
    const editingUser = this.props.editingUser;
    const deletingUser = this.props.deletingUser;
    return (
      <div>
        <Auth />
        <TopBar />
        <ErrorDialog onClose={this.onCloseError} />
        <div className={clz.container}>
          <AdminBar
            myState="adminForm"
            newButtonIsEnabled={true}
            searchBarIsEnabled={true}
            onNew={this.onBeginEdit}
            searchText={this.props.search}
          />
        <TableContainer className={clz.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={clz.header}>
                <TableCell>UID</TableCell>
                <TableCell>担当者名</TableCell>
                <TableCell>Email</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.searchAdminUsers.map((user, index) => {
                const deletable = this.props.user && this.props.user.uid !== user.uid;
                return (
                  <TableRow key={user.uid} className={clz[index % 2 ? 'oddRow' : 'evenRow']}>
                    <TableCell>{user.uid}</TableCell>
                    <TableCell className={clz.centerCell}>{user.displayName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell className={clz.centerCell + ' ' + clz.buttonsContainer}>
                      <div className={clz.buttons}>
                        <div className={clz.button}>
                          <Edit onClick={e =>  this.onBeginEdit(e, user.uid)} />
                        </div>
                        {deletable ?
                          <div className={clz.button}>
                            <Trash onClick={e => (deletable ? this.onBeginDelete(e, user.uid) : null)} />
                          </div>
                        :
                          null
                        }
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <ModalWrapper
          open={!!editingUser}
          onClose={(e) => this.onEndEdit(e)}
          content={
            <div className={clz.editTable}>
              <div className={clz.row}>
                <div className={clz.header}>UID</div>
                <div className={clz.inputCell}>
                  <div className={clz.uid}>
                    {(editingUser && editingUser.uid) || '(新規)'}
                  </div>
                </div>
                <div className={clz.header}></div>
              </div>
              <div className={clz.row}>
                <div className={clz.header}>名前</div>
                <div className={clz.inputCell}>
                  <TextField
                    className={clz.inputField}
                    name="displayName"
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ style: inputFieldStyles }}
                    value={(editingUser && editingUser.displayName) || ''}
                    onChange={this.onChange} />
                </div>
                <div className={clz.header}></div>
              </div>
              <div className={clz.row}>
                <div className={clz.header}>Email</div>
                <div className={clz.inputCell}>
                  <TextField
                    className={clz.inputField}
                    name="email"
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ style: inputFieldStyles }}
                    value={(editingUser && editingUser.email) || ''}
                    onChange={this.onChange} />
                </div>
                <div className={clz.header}></div>
              </div>
              <p className={clz.emailReserved} style={this.props.editingUser && this.props.editingUser.email && !regex.test(this.props.editingUser.email) ? {display: "block"} : {display: "none"}}>メールアドレス形式での入力が必要</p>
              {!(editingUser && editingUser.uid) ?
                <div className={clz.row}>
                  <div className={clz.header}>パスワード</div>
                  <div className={clz.inputCell}>
                    <TextField
                      className={clz.inputField}
                      name="password"
                      type="password"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ style: inputFieldStyles }}
                      onChange={this.onChange} />
                  </div>
                  <div className={clz.header}></div>
                </div>
              : null}
              {<p className={clz.reserved} style={this.props.editingUser && this.props.editingUser.password && this.props.editingUser.password.length < 6 ? {display: "block"} : {display: "none"}}>6文字以上</p>}
              {!(editingUser && editingUser.uid) ?
                <div className={clz.row}>
                  <div className={clz.header} style={{ width: 134 }}>パスワード確認</div>
                  <div className={clz.inputCell + ' ' + clz.passwordComfirm}>
                    <TextField
                      className={clz.inputField}
                      name="passwordConfirm"
                      type="password"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ style: inputFieldStyles }}
                      onChange={this.onChange} />
                  </div>
                  <div className={clz.header}></div>
                </div>
              : null}
              <InlineError />
              <div className={clz.row} style={{ justifyContent: 'center'}}>
                <div className={clz.editButtons}>
                  <div>
                    <button className={clz.dismissButton} onClick={this.onEndEdit}>キャンセル</button>
                  </div>
                  <div>
                    <button className={clz.applyButton} onClick={e => this.onEndEdit(e, true)}>
                      {editingUser && editingUser.uid ? '更新' : '登録'}する</button>
                  </div>
                </div>
              </div>
              <div className={clz.header}></div>
            </div>
          }/>
          <ModalWrapper
            open={!!editingUser && this.state.executing}
            noCloseButton={true}
            noMinSizing={true}
            content={
              <p className={clz.registering}>登録中…</p>
            } />
          <ModalWrapper
            open={!!deletingUser && this.state.executing}
            noCloseButton={true}
            noMinSizing={true}
            content={
              <p className={clz.registering}>削除中…</p>
            } />
          <ModalWrapper
            open={!!deletingUser}
            onClose={e => this.onCancelDelete(e)}
            content={
              <div className={clz.deleteTable}>
                <div>
                  <div className={clz.deleteMessage}>
                    <div className={clz.wordKeepAll}>「{deletingUser && deletingUser.displayName}</div>
                    <div className={clz.wordKeepAllClose}>」</div>
                    を削除しますか？
                  </div>
                  <InlineError />
                </div>
                <div className={clz.editButtons} style={{ marginTop: '18px' }}>
                  <div>
                    <button className={clz.applyButton} onClick={this.onDelete}>削除する</button>
                  </div>
                  <div>
                    <button className={clz.dismissButton} onClick={e => this.onCancelDelete(e)}>キャンセル</button>
                  </div>
                </div>
              </div>
            } />
        </div>
      </div>
    );
  }
}

const buttonStyle = {
  width: '160px',
  height: '48px',
  borderRadius: '24px',
  fontFamily: 'Hiragino Kaku Gothic Pro',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '18px',
  lineHeight: '48px',
  textAlign: 'center',
  borderStyle: 'none',
  outline: 'none',
  cursor: 'pointer',
};

const inputFieldStyles = {
  height: '48px',
  fontFamily: 'Hiragino Kaku Gothic Pro',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  padding: '0px 26px',
  borderRadius: '24px',
  lineHeight: '24px',
  color: '#444444',
};

const styles = {
  container: {
    height: 'calc(100vh - 56px - 48px - 80px)',
    minWidth: 'calc(1024px - 80px - 48px)',
    margin: '24px',
    padding: '40px',
    background: '#FFFFFF',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
  },
  table: {
    height: 'calc(100vh - 56px - 48px - 80px - 32px)',
    margin: '12px 0',
    '& tr': {
      '& > th': {
        borderBottom: '0',
        lineHeight: '14px',
      },
      '& > td': {
        borderBottom: '0',
        lineHeight: '15px',
      },
      '& > td:first-child': {
        borderRadius: '8px 0 0 8px',
      },
      '& > td:last-child': {
        borderRadius: '0 8px 8px 0',
      },
    },
  },
  oddRow: {
    background: '#FFFFFF',
  },
  evenRow: {
    background: '#F9F9F9',
  },
  centerCell: {
    textAlign: 'center',
  },
  buttonsContainer: {
    width: '80px',
    marginRight: '24px',
  },
  buttons: {
    display: 'flex',
  },
  button: {
    width: '32px',
    height: '32px',
    margin: '0 4px',
    cursor: 'pointer',
    backgroundColor: '#38496D',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: "#4360AA",
    },
  },
  editTable: {
    margin: '12px 0',
  },
  deleteTable: {
    margin: '12px 0',
    width: '540px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    height: '72px',
    display: 'flex',
  },
  header: {
    flex: '1',
    minWidth: '90px',
    margin: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '48px',
    textAlign: 'right',
    color: '#AAAAAA',
  },
  inputCell: {
    ...inputFieldStyles,
    height: '48px',
    width: '380px',
    margin: '12px',
    padding: '0',
    lineHeight: '48px',
  },
  passwordComfirm: {
    marginBottom: '16px',
  },
  uid: {
    padding: '0 26px',
  },
  inputField: {
    width: '100%',
    height: '48px',
    background: '#EEEEEE',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    '& div.MuiInput-root': {
      height: '100%',
    }
  },
  editButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
  deleteMessage: {
    width: '544px',
    marginBottom: '18px',
    textAlign: 'center',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '27px',
  },
  applyButton: {
    ...buttonStyle,
    marginLeft: '8px',
    marginRight: '8px',
    color: '#FFFFFF',
    background: '#38496D',
    '&:hover': {
      backgroundColor: '#4360AA',
    },
    '&:disabled': {
      backgroundColor: '#D3D3D3',
      cursor: 'default',
    },
  },
  dismissButton: {
    ...buttonStyle,
    marginLeft: '8px',
    marginRight: '8px',
    background: '#AAAAAA',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#CCCCCC',
    },
    '&:disabled': {
      backgroundColor: '#E0E0E0',
      cursor: 'default',
    },
  },
  reserved: {
    margin: '0 31em',
    fontSize: '10px',
    fontWeight: '700',
    color: "#EE0000",
  },
  emailReserved: {
    margin: '0',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: '700',
    color: "#EE0000",
  },
  registering: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#444444',
  },
  wordKeepAll: {
    display: 'inline-block',
    verticalAlign: 'top',
    textAlign: 'right',
    maxWidth: 'calc(100% - 20px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wordKeepAllClose: {
    display: 'inline-block',
    textAlign: 'left',
    verticalAlign: 'top',
    width: '20px',
  },
};

export default withRouter(withStyles(styles)(
  connect(state => ({
    user: state.user && state.user.user,
    adminUsers: (state.admin && state.admin.adminUsers) || [],
    editingUser: state.admin && state.admin.editingUser,
    deletingUser: state.admin && state.admin.deletingUser,
    search: state.admin && state.admin.search,
    searchAdminUsers: (state.admin && state.admin.searchAdminUsers) || [],
  }))(AdminForm)
));
