import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { addLabel, deleteLabel, defaultLabel, editCompany, updateCompany } from './actions/companies';
import Auth from './auth';
import { TextField, Select, MenuItem } from "@material-ui/core";
import ModalWrapper from './ModalWrapper';
import DownArrowIcon from './svg/DownArrowIcon';
import { InlineError } from "./errorMessage";
import { appRemoveError } from "./actions/appAction";

const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

class CompanyForm extends Component {
  state = {
    executing: false,
    dirtyMap: {},
  }

  componentDidMount() {
    appRemoveError(this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.selectedCompany && prevProps.selectedCompany) {
      this.setState({
        executing: false,
      });
      if (this.props.onClose) {
        this.props.onClose();
      }
      return;
    }
  }

  onChange = (e, i) => {
    const company = { ...this.props.selectedCompany };
    if (Array.isArray(company[e.target.name])) {
      company[e.target.name][i] = e.target.value;
    } else {
      company[e.target.name] = e.target.value;
    }
    editCompany(this.props.dispatch, company, this.props.actives);
    const dirtyMap = { ...this.state.dirtyMap };
    dirtyMap[e.target.name] = true;
    appRemoveError(this.props.dispatch);
    this.setState({
      dirtyMap: dirtyMap,
    });
  }

  onChangeLabel = (e, i) => {
    const company = { ...this.props.selectedCompany };
    company.labels[i][e.target.name] = e.target.value;
    editCompany(this.props.dispatch, company, this.props.actives);
    const dirtyMap = { ...this.state.dirtyMap };
    dirtyMap.labels = true;
    appRemoveError(this.props.dispatch);
    this.setState({
      dirtyMap: dirtyMap,
    });
  }

  onAddCompanyContactMail = () => {
    const company = { ...this.props.selectedCompany };
    company.companyContactMails.push('');
    editCompany(this.props.dispatch, company, this.props.actives);
    appRemoveError(this.props.dispatch);
  }

  onDeleteCompanyContactMail = (index) => {
    const company = { ...this.props.selectedCompany };
    if (company.companyContactMails.length > 1) {
      company.companyContactMails = company.companyContactMails.slice(0, index).concat(
        company.companyContactMails.slice(index + 1)
      );
    } else {
      company.companyContactMails = [""];
    }
    editCompany(this.props.dispatch, company, this.props.actives);
    appRemoveError(this.props.dispatch);
    this.setState({
      dirtyMap: {
        ...this.state.dirtyMap,
        companyContactMails: true,
      },
    });
  }

  onAddAutomaticApprovalMail = () => {
    const company = { ...this.props.selectedCompany };
    company.automaticApprovalMails.push('');
    editCompany(this.props.dispatch, company, this.props.actives);
    appRemoveError(this.props.dispatch);
  }

  onDeleteAutomaticApprovalMail = (index) => {
    const company = { ...this.props.selectedCompany };
    if (company.automaticApprovalMails.length > 1) {
      company.automaticApprovalMails = company.automaticApprovalMails.slice(0, index).concat(
        company.automaticApprovalMails.slice(index + 1)
      );
    } else {
      company.automaticApprovalMails = [""];
    }
    editCompany(this.props.dispatch, company, this.props.actives);
    appRemoveError(this.props.dispatch);
    this.setState({
      dirtyMap: {
        ...this.state.dirtyMap,
        automaticApprovalMails: true,
      },
    });
  }

  onAddLabel = () => {
    addLabel(this.props.dispatch);
    appRemoveError(this.props.dispatch);
  }

  onDeleteLabel = (index) => {
    const company = { ...this.props.selectedCompany };
    const labels = company.labels;
    if (labels.length > 1) {
      deleteLabel(this.props.dispatch, index);
    } else {
      defaultLabel(this.props.dispatch);
    }
    appRemoveError(this.props.dispatch);
  }

  validate = () => {
    const company = this.props.selectedCompany;
    const invalidates = {};
    ['companyName', 'companyContactName', 'companyContactDepartment', 'companyContactTel'].forEach(key => {
      const value = company[key];
      if (!value.trim().length) {
        invalidates[key] = true;
      }
    });
    ['companyContactMails', 'automaticApprovalMails'].forEach(key => {
      const values = company[key];
      const notEmptyValues = values.filter(x => x.trim().length !== 0);
      const duplicateValues = notEmptyValues.filter((x, i, self) => self.indexOf(x) !== i);
      if (!notEmptyValues.length || duplicateValues.length) {
        invalidates[key] = true;
        return;
      }
      if (notEmptyValues.some(value => !regex.test(value))) {
        invalidates[key] = true;
        return;
      }
    });
    ['labels'].forEach(key => {
      const idColumn = 'label';
      const labels = company[key];
      ['labelName'].forEach(column => {
        const values = labels.map(x => x[column]);
        const notEmptyValues = values.filter(x => x.trim().length !== 0);
        const duplicateValues = notEmptyValues.filter((x, i, self) => self.indexOf(x) !== i);
        if (!notEmptyValues.length || duplicateValues.length) {
          invalidates[key] = true;
          return;
        }
        if (labels.filter(x => x[idColumn]).length > 0) {
          // 編集時
          const alreadyRegisterdValues = labels.filter(x => x[idColumn]).map(x => x[column]);
          const alreadyRegisterdEmptyValues = alreadyRegisterdValues.filter(x => x.trim().length === 0);
          if (alreadyRegisterdEmptyValues.length) {
            invalidates[key] = true;
            return;
          }
        }
      });
    });
    return invalidates;
  }

  onUpdate = async () => {
    if (this.state.executing) return;
    const company = { ...this.props.selectedCompany };
    const invalidates = this.validate();
    if (!Object.keys(invalidates).length) {
      this.setState({
        executing: true,
        dirtyMap: {},
      });
      await updateCompany(this.props.dispatch, company);
      this.setState({
        executing: false,
      });
    } else {
      this.setState({
        dirtyMap: invalidates,
      });
    }
  }

  getMails = (key) => {
    const mails = this.props.selectedCompany[key] || [];
    return mails.length ? mails : [''];
  }

  showReserved = (key) => {
    const value = this.props.selectedCompany[key];
    const nonEmpty = Array.isArray(value) ? value.some(v => v.trim().length) : value.trim().length;
    return {
      display: this.state.dirtyMap[key] && !nonEmpty ? 'block' : 'none',
    };
  }

  showReservedForLabel = (key, index) => {
    const value = this.props.selectedCompany.labels[index][key];
    const nonEmpty = Array.isArray(value) ? value.some(v => v.trim().length) : value.trim().length;
    return {
      display: this.state.dirtyMap.labels && !nonEmpty ? 'block' : 'none',
    };
  }

  duplicateDetection = (key, email, index) => {
    const company = this.props.selectedCompany;
    const emails = company[key].concat();
    emails.splice(index, 1);
    const contain = emails.some(value => value === email);
    return {
      display: contain ? 'block' : 'none',
    };
  }

  duplicateDetectionForLabel(key, label, index) {
    const labelOnlyCheckColumn = this.props.selectedCompany.labels.map(label => label[key]);
    labelOnlyCheckColumn.splice(index, 1);
    const contain = labelOnlyCheckColumn.some(value => value === label[key]);
    return {
      display: contain ? 'block' : 'none',
    };
  }

  render() {
    if (!this.props.selectedCompany) return null;
    const clz = this.props.classes;
    const company = this.props.selectedCompany;
    const labels = company.labels.length ? company.labels : [];
    const isEdit = !!company.cid;
    const isDisabledUnderline = {
      disableUnderline: true,
    };
    const incrButtonStyle = {
      width: isEdit ? '36px' : '100px',
    };
    const emptyIncrButtonStyle = {
      width: isEdit ? '3px' : '100px',
    };
    const paperStyle = {
      display: isEdit ? 'flex' : 'block',
      overflowY: isEdit ? 'hidden' : null,
    };
    const mailInputFormStyle = {
      width: isEdit ? '197px' : '230px',
    };
    const rightContentStyle = {
      width: isEdit ? '100%' : 'auto',
      display: isEdit ? 'flex' : 'block',
      marginLeft: isEdit ? '32px' : '0',
      flexDirection: isEdit ? 'column' : null,
    };
    const descriptionRowStyle = {
      flexDirection: isEdit ? 'column' : 'row',
    };
    const descriptionStyle = {
      marginTop: isEdit ? '16px' : 'auto',
      width: isEdit ? '100%' : 'auto',
      height: isEdit ? 'calc(7em + 2px + 16px + 14px)' : 'auto',
    };
    const labelsContainerStyle = {
      display: isEdit ? 'flex' : 'block',
      width: isEdit ? 'calc(100% - 2px)' : 'auto',
      minHeight: '0',
      marginRight: isEdit ? '12px' : 'auto',
      padding: isEdit ? '4px' : '0',
    };
    const labelsBorderStyle = {
      width: isEdit ? 'calc(100% - 2px - 16px - 12px)' : 'auto',
      border: isEdit ? '1px solid #AAAAAA' : '0',
      padding: isEdit ? '8px' : null,
      borderRadius: isEdit ? '8px' : '0',
      overflowY: isEdit ? 'auto' : null,
    };
    const labelInputStyle = {
      width: isEdit ? '144px' : '266px',
    };
    const labelIncrButtonStyle = {
      marginRight: isEdit ? '16px' : '20px',
    };
    const activesStyle = {
      display: isEdit ? 'block' : 'none',
    };
    return (
      <React.Fragment>
        <div className={clz.wrapper}>
          <Auth />
          <div className={clz.title}>会社情報 {isEdit ? "編集" : "新規登録"}</div>
          {/* Chromeにて「設定 > 住所やその他の情報」を設定すると自動補完が発動する */}
          {/* 普通にautoComplete="off"にしてもユーザが設定したものに関しては、ブラウザが優先して自動補完させようとしてしまう */}
          {/* 以下のinputはこれらの自動補完を無効にするために設置。このinputの設置と各TextFieldのautoCompleteに存在しない値を設定することでChromeによる自動補完を無効にする。 */}
          <input name="tel" autoComplete="off" style={{ display: "none" }} tabIndex="-1" disabled />
          <div className={clz.paper} style={paperStyle}>
            <div className={clz.leftContent}>
              <div className={clz.row}>
                <div className={clz.subtitle}>会社名</div>
                <div className={clz.input}>
                  <TextField name="companyName" value={company.companyName} autoComplete="無効な値" InputProps={{ ...isDisabledUnderline }} inputProps={{ className: clz.inputForm }} onChange={this.onChange} />
                  <p className={clz.reserved} style={this.showReserved('companyName')}>必須入力</p>
                </div>
                <div className={clz.incrButtonArea} style={emptyIncrButtonStyle}></div>
              </div>
              <div className={clz.row}>
                <div className={clz.subtitle}>担当者名</div>
                <div className={clz.input}>
                  <TextField name="companyContactName" value={company.companyContactName} autoComplete="無効な値" InputProps={{ ...isDisabledUnderline }} inputProps={{ className: clz.inputForm }} onChange={this.onChange} />
                  <p className={clz.reserved} style={this.showReserved('companyContactName')}>必須入力</p>
                </div>
                <div className={clz.incrButtonArea} style={emptyIncrButtonStyle}></div>
              </div>
              <div className={clz.row}>
                <div className={clz.subtitle}>担当者部署</div>
                <div className={clz.input}>
                  <TextField name="companyContactDepartment" value={company.companyContactDepartment} autoComplete="無効な値" InputProps={{ ...isDisabledUnderline }} inputProps={{ className: clz.inputForm }} onChange={this.onChange} />
                  <p className={clz.reserved} style={this.showReserved('companyContactDepartment')}>必須入力</p>
                </div>
                <div className={clz.incrButtonArea} style={emptyIncrButtonStyle}></div>
              </div>
              <div className={clz.row}>
                <div className={clz.subtitle}>担当者電話番号</div>
                <div className={clz.input}>
                  <TextField name="companyContactTel" value={company.companyContactTel} autoComplete="無効な値" InputProps={{ ...isDisabledUnderline }} inputProps={{ className: clz.inputForm }} onChange={this.onChange} />
                  <p className={clz.reserved} style={this.showReserved('companyContactTel')}>必須入力</p>
                </div>
                <div className={clz.incrButtonArea} style={emptyIncrButtonStyle}></div>
              </div>
              { this.getMails('companyContactMails').map((item, i) => {
                const isFirst = i === 0;
                return (
                  <div key={i} className={clz.row + ' ' + clz.mailRow}>
                    <div className={clz.subtitle}>担当者メールアドレス{(i + 1)}</div>
                    <div className={clz.input}>
                      <TextField name="companyContactMails" value={item} autoComplete="無効な値" InputProps={{ ...isDisabledUnderline }} inputProps={{ className: clz.inputForm, style: { ...mailInputFormStyle } }} onChange={e => this.onChange(e, i)} />
                      {/* {i ? undefined : (<p className={clz.reserved} style={this.showReserved('companyContactMails')}>必須入力</p>)} */}
                      {item ?
                        <React.Fragment>
                          {regex.test(item) ?
                            <p className={clz.reserved} style={this.duplicateDetection('companyContactMails', item, i)}>重複</p>
                          :
                            <p className={clz.reserved} style={{display: "block"}}>メールアドレス形式での入力が必要</p>
                          }
                        </React.Fragment>
                      :
                        <React.Fragment>
                          {isFirst && <p className={clz.reserved} style={this.showReserved('companyContactMails')}>必須入力</p>}
                        </React.Fragment>
                      }
                    </div>
                    <div className={clz.incrButtonArea} style={incrButtonStyle}>
                      <div className={clz.insideIncrButtonArea}>
                        { <span
                          className={clz.incrButton}
                          onClick={() => this.onDeleteCompanyContactMail(i)}>
                          [ － ]
                        </span> }
                      </div>
                    </div>
                  </div>
                )
              }) }
              <div className={clz.row + ' ' + clz.incrRow}>
                <div className={clz.incrButtonArea} style={incrButtonStyle}>
                  <div className={clz.insideIncrButtonArea}>
                    <span
                      className={clz.incrButton}
                      onClick={this.onAddCompanyContactMail}>
                      [ ＋ ]
                    </span>
                    </div>
                </div>
              </div>
              { this.getMails('automaticApprovalMails').map((item, i) => {
                const isFirst = i === 0;
                return (
                  <div key={i} className={clz.row + ' ' + clz.mailRow}>
                    <div className={clz.subtitle}>自動承認メールアドレス{(i + 1)}</div>
                    <div className={clz.input}>
                      <TextField name="automaticApprovalMails" value={item} autoComplete="無効な値" InputProps={{ ...isDisabledUnderline }} inputProps={{ className: clz.inputForm, style: { ...mailInputFormStyle } }} onChange={e => this.onChange(e, i)} />
                      {/* {i ? undefined : (<p className={clz.reserved} style={this.showReserved('automaticApprovalMails')}>必須入力</p>)} */}
                      {item ?
                        <React.Fragment>
                          {regex.test(item) ?
                            <p className={clz.reserved} style={this.duplicateDetection('automaticApprovalMails', item, i)}>重複</p>
                          :
                            <p className={clz.reserved} style={{display: "block"}}>メールアドレス形式での入力が必要</p>
                          }
                        </React.Fragment>
                      :
                        <React.Fragment>
                          {isFirst && <p className={clz.reserved} style={this.showReserved('automaticApprovalMails')}>必須入力</p>}
                        </React.Fragment>
                      }
                    </div>
                    <div className={clz.incrButtonArea} style={incrButtonStyle}>
                      <div className={clz.insideIncrButtonArea}>
                        <span
                          className={clz.incrButton}
                          onClick={() => this.onDeleteAutomaticApprovalMail(i)}>
                            [ － ]
                        </span>
                      </div>
                    </div>
                  </div>
                )
              }) }
              <div className={clz.row + ' ' + clz.incrRow}>
                <div className={clz.incrButtonArea} style={incrButtonStyle}>
                  <div className={clz.insideIncrButtonArea}>
                    <span
                      className={clz.incrButton}
                      onClick={this.onAddAutomaticApprovalMail}>
                      [ ＋ ]
                    </span>
                    </div>
                </div>
              </div>
            </div>
            <div className={clz.rightContent} style={rightContentStyle}>
              <div className={clz.row + ' ' + clz.descriptionRow} style={descriptionRowStyle}>
                <div className={clz.subtitle + ' ' + clz.descriptionSubtitle}>備考</div>
                <div className={clz.description} style={descriptionStyle}>
                  <TextField
                    name="companyDescription"
                    value={company.companyDescription}
                    autoComplete="off"
                    placeholder="ここに備考内容を入力してください。"
                    onChange={this.onChange}
                    multiline={true}
                    rows={4}
                    InputProps={{ disableUnderline: true}} />
                </div>
              </div>
              <div className={clz.row}>
                <div className={clz.subtitle}>
                  バーチャル警備員
                </div>
              </div>
              <div style={labelsContainerStyle}>
                <div style={labelsBorderStyle}>
                  { labels.map((label, i) => {
                    const isFirst = i ===0;
                    return (
                      <div key={i} className={clz.row + ' ' + clz.labelRow}>
                        <div className={clz.subtitle + ' ' + clz.labelSubtitle}>ラベル{isEdit ? "" : "名"}</div>
                        <div className={clz.labelInput}  style={ labelInputStyle }>
                          <TextField name="labelName" value={label.labelName} autoComplete="off" InputProps={{ ...isDisabledUnderline }} inputProps={{ className: clz.inputForm }}
                            onChange={(e) => this.onChangeLabel(e, i)} />
                            {label.labelName.trim() ?
                              <React.Fragment>
                                <p className={clz.reserved} style={this.duplicateDetectionForLabel('labelName', label, i)}>重複</p>
                              </React.Fragment>
                            :
                              <React.Fragment>
                                {isEdit ?
                                  label.label && <p className={clz.reserved} style={this.showReservedForLabel('labelName', i)}>必須入力</p>
                                :
                                  isFirst && <p className={clz.reserved} style={this.showReservedForLabel('labelName', i)}>必須入力</p>
                                }
                              </React.Fragment>
                            }
                        </div>
                        <div className={clz.subtitle + ' ' + clz.labelSubtitle}>設置台数</div>
                        <div className={clz.vguardCount}>
                          <Select
                            name="vguardMaxCount"
                            value={label.vguardMaxCount}
                            disableUnderline
                            IconComponent={() => <DownArrowIcon className="MuiSelect-icon" viewBox="0 -10 24 24"/>}
                            onChange={(e) => this.onChangeLabel(e, i)}>
                              <MenuItem value="0">0</MenuItem>
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                          </Select>
                        </div>
                        <div className={clz.subtitle + ' ' + clz.labelSubtitle + ' ' + clz.labelDescription}>(0～3)</div>
                        <div className={clz.subtitle + ' ' + clz.labelSubtitle + ' ' + clz.labelActives} style={ activesStyle }>
                          Active {(this.props.actives && this.props.actives[label.label]) || 0}
                        </div>
                        <div className={clz.incrButtonArea} style={labelIncrButtonStyle}>
                          <div className={clz.insideIncrButtonArea}>
                            { !label.createdAt &&
                              <span
                                className={clz.incrButton}
                                onClick={() => this.onDeleteLabel(i)}>
                                [ － ]
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    )
                  }) }
                  <div className={clz.row + ' ' + clz.incrRow}>
                    <div className={clz.incrButtonArea} style={labelIncrButtonStyle}>
                      <div className={clz.insideIncrButtonArea}>
                        <span
                          className={clz.incrButton}
                          onClick={this.onAddLabel}>
                          [ ＋ ]
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <InlineError />
          <div className={clz.buttonArea}>
            <button className={clz.applyButton} onClick={this.onUpdate}> {isEdit ? "更新する" : "登録する"}</button>
          </div>
        </div>
        <ModalWrapper
          open={this.state.executing}
          noCloseButton={true}
          noMinSizing={true}
          content={
            <p className={clz.registering}>登録中…</p>
          } />
      </React.Fragment>
    )
  }
}

const styles = {
  wrapper: {
    width: '100%',
    minWidth: '624px',
    maxHeight: 'calc(100vh - 56px - 64px * 2)',
    margin: '0 auto',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    width: '100%',
    padding: '15px 4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    listStyle: 'none',
  },
  title: {
    width: '100%',
    height: '28px',
    marginBottom: '32px',
    textAlign: 'center',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
  },
  paper: {
    minWidth: '640px',
    flex: '1',
    overflowX: 'hidden',
    marginBottom: '16px',
  },
  leftContent: {
    width: '100%',
    minWidth: '480px',
    overflowY: "auto",
    overflowX: "hidden",
  },
  rightContent: {
    width: '100%',
    minWidth: '540px',
    overflowY: "auto",
    overflowX: "hidden",
  },
  subtitle: {
    height: '14px',
    width: '177px',
    marginRight: '-15px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    verticalAlign: 'middle',
    textAlign: 'left'
  },
  input: {
  },
  inputForm : {
    height: '24px',
    width: '230px',
    paddingLeft: '18px',
    paddingRight: '18px',
    borderRadius: '12px',
    backgroundColor: '#eeeeee',
  },
  mailRow: {
    paddingBottom: '0',
  },
  descriptionRow: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  descriptionSubtitle: {
    width: '60px',
  },
  description: {
    flex: '10',
    height: '7em',
    '& .MuiInputBase-multiline': {
      padding: '0',
    },
    '& textarea': {
      width: '100%',
      height: '6em',
      marginRight:'16px',
      padding: '0.5em',
      border: '1px solid #AAAAAA',
      borderRadius: '8px',
    }
  },
  labelRow: {
    paddingBottom: '0',
  },
  labelSubtitle: {
    flex: 'none',
    width: 'auto',
    fontSize: '12px',
  },
  labelInput: {
    flex: 'none',
    marginRight: '0',
    '& .MuiInput-input': {
      width: '90%',
      fontSize: '16px',
      color: '#38496d',
    },
  },
  labelDescription: {
    color: '#AAAAAA',
  },
  labelActives: {
    color: '#AAAAAA',
  },
  vguardCount: {
    width: '64px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '16px',
    background: '#EEEEEE',
    borderRadius: '12px',
    color: '#38496d',
    '& div.MuiSelect-select.MuiSelect-select': {
      padding: '4px 16px',
      fontSize: '16px',
      lineHeight: '16px',
    },
    '& .MuiInput': {
      '&:hover': {
        borderBottom: '0px',
        borderColor: 'transparent'
      },
      underline: {
        '&:after': {
          borderBottom: '0px',
          borderColor: 'transparent'
        },
        '&:hover': {
          borderBottom: '0px',
          borderColor: 'transparent'
        }
      }
    }
  },
  incrRow: {
    paddingBottom: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  incrButtonArea: {
    width: '36px',
    marginRight: '20px',
  },
  insideIncrButtonArea: {
    width: '36px',
    height: '14px',
    marginLeft: 'auto',
    textAlignLast: 'justify',
    transform: 'translate(0, -0.5em)',
  },
  incrButton: {
    height: '14px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#38495D',
    cursor: 'pointer',
  },
  buttonArea: {
    marginTop: '16px',
    height: '48px',
    textAlign: 'center',
  },
  applyButton: {
    width: '160px',
    height: '48px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    background: '#38496D',
    color: '#FFFFFF',
    borderStyle: 'none',
    borderRadius: '24px',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#4360AA',
    },
    '&:disabled': {
      backgroundColor: '#D3D3D3',
      cursor: 'default',
    },
  },
  registering: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#444444',
  },
  reserved: {
    margin: '0 1em',
    fontSize: '10px',
    fontWeight: '700',
    color: "#EE0000",
  },
};

export default withStyles(styles)(withRouter(connect(state => {
  //console.log(JSON.stringify(state));
  return {
    user: state.user && state.user.user,
    companies: (state.companies && state.companies.companies) || [],
    selectedCompany: state.companies && state.companies.selectedCompany,
    actives: state.companies && state.companies.actives,
  };
})(CompanyForm)));
