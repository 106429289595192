const defaultState = {
  dialogError: null,
  inlineError: null,
};

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'DIALOG_ERROR':
      return {
        ...state,
        dialogError: action.error,
        inlineError: null,
      };
    case 'INLINE_ERROR':
      return {
        ...state,
        dialogError: null,
        inlineError: action.error,
      };
    case 'APP_REMOVE_ERROR':
      return {
        ...state,
        dialogError: null,
        inlineError: null,
      }
    default:
      return state;
  }
}

export default appReducer;
