import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import Close from './svg/Close';

const styles = {
  wrapper: {
    position: 'absolute',
    //width: theme.spacing.unit * 50,
    maxWidth: 'calc(100vw - 96px)',
    maxHeight: 'calc(100vh - 56px - 32px)',
    minWidth: '640px',
    minHeight: '480px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: '36px 48px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1);',
    borderRadius: '16px',
    outline: 'none',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, calc(-50% + 28px))`,
  },
  errorWrapper: {
    position: 'absolute',
    width: '640px',
    height: '480px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1);',
    borderRadius: '16px',
    outline: 'none',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, calc(-50% + 28px))`,
  },
  close: {
    width: '40px',
    height: '40px',
    position: 'absolute',
    top: '-20px',
    right: '-20px',
    cursor: 'pointer',
  },
};

class ModalWrapper extends Component {
  render() {
    if (!this.props.open) return null;
    const clz = this.props.classes;
    const noMinSizing = this.props.noMinSizing;
    const wrapperStyle = {
      minWidth: noMinSizing ? 'auto' : null,
      minHeight: noMinSizing ? 'auto' : null,
    }
    return (
      <Modal id='Modal'
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={this.props.open}
        onClose={this.props.onClose}>
        <div className={this.props.error ? clz.errorWrapper : clz.wrapper} style={wrapperStyle}>
          <Close onClick={this.props.onClose} className={clz.close} style={{ display: this.props.noCloseButton ? 'none' : 'initial' }} />
          {this.props.content}
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(ModalWrapper);
