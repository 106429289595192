import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import { withStyles, Snackbar, Slide, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { beginEditAdminUser, editAdminUser, updateAdminUser, cancelEditAdminUser } from './actions/admin';
import Auth from './auth';
import TopBar from './TopBar';
import AdminBar from './AdminBar';
import ModalWrapper from './ModalWrapper';
import { ErrorDialog, InlineError } from "./errorMessage";
import { appRemoveError, inlineError } from './actions/appAction';

export class PasswordForm extends Component {
  state = {
    executing: false,
    showToast: false,
  }

  componentDidMount() {
    if (this.props.user && !this.props.editingUser) {
      beginEditAdminUser(this.props.dispatch, this.props.user.uid, 'passwordOnly');
    }
    appRemoveError(this.props.dispatch);
  }

  componentDidUpdate() {
    if (this.props.user && !this.props.editingUser) {
      beginEditAdminUser(this.props.dispatch, this.props.user.uid, 'passwordOnly');
    }
    if (this.state.executing) {
      if (!this.props.editingUser) {
        this.setState({
          executing: false,
        });
      }
    }
  }

  onChange = (e) => {
    const editingUser = { ...this.props.editingUser };
    editingUser[e.target.name] = e.target.value;
    editAdminUser(this.props.dispatch, editingUser);
    appRemoveError(this.props.dispatch);
  }

  onEndEdit = async (e, save) => {
    e.preventDefault();
    if (this.state.executing) return;
    if (save) {
      //TODO: validator
      if (!this.props.editingUser.currentPassword) {
        inlineError(this.props.dispatch, '現在のパスワードを入力してください');
        return;
      }
      if (!this.props.editingUser.password) {
        inlineError(this.props.dispatch, 'パスワードを指定してください');
        return;
      }
      if (this.props.editingUser.password !== this.props.editingUser.passwordConfirm) {
        inlineError(this.props.dispatch, 'パスワードが一致しません');
        return;
      }
      if (this.props.editingUser.password.length < 6) {
        inlineError(this.props.dispatch, '6文字以上');
        return;
      }
      this.setState({
        executing: true,
      });
      const success = await updateAdminUser(this.props.dispatch, { ...this.props.editingUser });
      this.setState({
        executing: false
      });
      if (!success) return;
      await beginEditAdminUser(this.props.dispatch, this.props.user.uid, 'passwordOnly');
      appRemoveError(this.props.dispatch);
      this.setState({
        showToast: true,
      });
    } else {
      appRemoveError(this.props.dispatch);
      cancelEditAdminUser(this.props.dispatch);
      beginEditAdminUser(this.props.dispatch, this.props.user.uid, 'passwordOnly');
    }
  }

  onCloseToast = () => {
    console.log('onCloseToast');
    this.setState({ showToast: false });
  }

  render() {
    const clz = this.props.classes;
    return (
      <div>
        <Auth />
        <TopBar />
        <ErrorDialog />
        <div className={clz.container}>
          <AdminBar
            myState="password"
            newButtonIsEnabled={false}
            searchBarIsEnabled={false}
            onNew={() => null}
          />
          <div className={clz.passwordForm}>
            <div className={clz.row + ' ' + clz.inputCell}>
              <TextField
                className={clz.inputField}
                type="password"
                name="currentPassword"
                placeholder="現在のパスワード"
                InputProps={{ disableUnderline: true }}
                inputProps={{ style: inputFieldStyles }}
                value={(this.props.editingUser && this.props.editingUser.currentPassword) || ''}
                onChange={this.onChange} />
            </div>
            <div className={clz.row + ' ' + clz.inputCell}>
              <TextField
                className={clz.inputField}
                type="password"
                name="password"
                placeholder="パスワード"
                InputProps={{ disableUnderline: true }}
                inputProps={{ style: inputFieldStyles }}
                value={(this.props.editingUser && this.props.editingUser.password) || ''}
                onChange={this.onChange} />
            </div>
            <div className={clz.row + ' ' + clz.inputCell + ' ' + clz.passwordComfirm}>
              <TextField
                className={clz.inputField}
                type="password"
                name="passwordConfirm"
                placeholder="パスワード確認"
                InputProps={{ disableUnderline: true }}
                inputProps={{ style: inputFieldStyles }}
                value={(this.props.editingUser && this.props.editingUser.passwordConfirm) || ''}
                onChange={this.onChange} />
            </div>
            <InlineError />
            <div className={clz.row + ' ' + clz.editButtons}>
              <div>
                <button className={clz.dismissButton} onClick={this.onEndEdit}>キャンセル</button>
              </div>
              <div>
                <button className={clz.applyButton} onClick={e => this.onEndEdit(e, true)}>登録する</button>
              </div>
            </div>
          </div>
          <ModalWrapper
            open={this.state.executing}
            noCloseButton={true}
            noMinSizing={true}
            content={
              <p className={clz.registering}>変更中…</p>
            } />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            TransitionComponent={Slide}
            open={this.state.showToast}
            autoHideDuration={6000}
            onClose={this.onCloseToast}
            message="パスワード変更が完了しました"
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={this.onCloseToast}
                  onClose={this.onCloseToast}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </div>
      </div>
    );
  }
}

const buttonStyle = {
  width: '160px',
  height: '48px',
  borderRadius: '24px',
  fontFamily: 'Hiragino Kaku Gothic Pro',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '18px',
  lineHeight: '48px',
  textAlign: 'center',
  borderStyle: 'none',
  outline: 'none',
  cursor: 'pointer',
};

const inputFieldStyles = {
  height: '48px',
  textAlign: 'center',
  fontFamily: 'Hiragino Kaku Gothic Pro',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  padding: '0px 26px',
  borderRadius: '24px',
  lineHeight: '24px',
  color: '#444444',
};

const styles = {
  container: {
    height: 'calc(100vh - 56px - 48px - 80px)',
    minWidth: 'calc(1024px - 80px - 48px)',
    margin: '24px',
    padding: '40px',
    background: '#FFFFFF',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
  },
  passwordForm: {
    height: 'calc(100vh - 56px - 48px - 80px - 32px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    height: '48px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '48px',
    textAlign: 'center',
  },
  inputCell: {
    ...inputFieldStyles,
    height: '48px',
    width: '380px',
    margin: '12px',
    padding: '0',
    lineHeight: '48px',
  },
  passwordComfirm: {
    marginBottom: '16px',
  },
  inputField: {
    width: '100%',
    height: '48px',
    background: '#EEEEEE',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    '& div.MuiInput-root': {
      height: '100%',
    }
  },
  state: {
    width: '100%',
    height: '32px',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#EE0000',
  },
  noState: {
    height: '8px',
    margin: '12px',
  },
  editButtons: {
    display: 'flex',
    marginTop: '16px',
  },
  applyButton: {
    ...buttonStyle,
    marginLeft: '8px',
    marginRight: '8px',
    color: '#FFFFFF',
    background: '#38496D',
    '&:hover': {
      backgroundColor: '#4360AA',
    },
    '&:disabled': {
      backgroundColor: '#D3D3D3',
      cursor: 'default',
    },
  },
  dismissButton: {
    ...buttonStyle,
    marginLeft: '8px',
    marginRight: '8px',
    background: '#AAAAAA',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#CCCCCC',
    },
    '&:disabled': {
      backgroundColor: '#E0E0E0',
      cursor: 'default',
    },
  },
  registering: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#444444',
  },

};

export default withRouter(withStyles(styles)(
  connect(state => ({
    user: state.user && state.user.user,
    editingUser: state.admin && state.admin.editingUser,
  }))(PasswordForm)
));
