import React from 'react';

export default function DataRepair(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16"/>
      <rect x="7" y="9" width="18" height="14" rx="1" stroke="white" strokeWidth="2"/>
      <rect x="6" y="12" width="20" height="2" fill="white"/>
      <rect x="13" y="17" width="6" height="2" fill="white"/>
    <rect x="17" y="15" width="6" height="2" transform="rotate(90 17 15)" fill="white"/>
    </svg>
  );
}
