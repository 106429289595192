export function dialogError(dispatch, e) {
  dispatch(dialogErrorAction(e));
}

export function inlineError(dispatch, e) {
  dispatch(inlineErrorAction(e));
}

export function appRemoveError(dispatch) {
  dispatch(appRemoveErrorAction());
}

export function dialogErrorAction(e) {
  let error = e.message;
  if(!error) error = e;
  return {
    type: "DIALOG_ERROR",
    error: error,
  };
}

export function inlineErrorAction(e) {
  let error = e.message;
  if(!error) error = e;
  return {
    type: "INLINE_ERROR",
    error: error,
  };
}

function appRemoveErrorAction() {
  return {
    type: "APP_REMOVE_ERROR",
  };
}

export function libraryErrorAction(errorCode) {
  return inlineErrorAction('ライブラリでエラーが発生しました。error code : ' + errorCode);
}

export function offLineErrorAction(messageTitle) {
  return inlineErrorAction(messageTitle + "中に会社管理ツールがオフラインになりました。");
}
