import React from 'react';

export default function Logo(props) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M42.6656 12.1907C37.6162 12.1907 33.5229 16.284 33.5229 21.3334C33.5229 21.3335 33.5229 21.3336 33.5229 21.3337H33.5222V30.4764H42.6649V30.4761C42.6651 30.4761 42.6653 30.4761 42.6656 30.4761H54.8558C59.9052 30.4761 63.9985 26.3827 63.9985 21.3334C63.9985 16.284 59.9052 12.1907 54.8558 12.1907H42.6656Z" fill="#9FA9C3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.364 33.5251L30.4768 33.5251L30.4768 42.6678L30.4757 42.6678C30.4757 47.7171 26.3824 51.8104 21.333 51.8104L9.14277 51.8104C4.0934 51.8104 7.66518e-05 47.7171 7.70932e-05 42.6677C7.75347e-05 37.6184 4.0934 33.525 9.14277 33.525L21.333 33.525C21.3434 33.525 21.3537 33.5251 21.364 33.5251Z" fill="#F8A01C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M51.8096 42.6675C51.8096 37.6181 47.7162 33.5248 42.6669 33.5248C42.6241 33.5248 42.5813 33.5251 42.5387 33.5257L33.5234 33.5257L33.5234 42.6684L33.5242 42.6684L33.5242 54.8577C33.5242 59.9071 37.6175 64.0004 42.6669 64.0004C47.7162 64.0004 51.8096 59.9071 51.8096 54.8577L51.8096 42.6675Z" fill="#555EB6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.1917 21.333C12.1917 26.3823 16.285 30.4757 21.3343 30.4757C21.3344 30.4757 21.3344 30.4757 21.3344 30.4757L21.3344 30.4759L30.4771 30.4759L30.4771 21.3332L30.477 21.3332C30.477 21.3331 30.477 21.333 30.477 21.333L30.477 9.14271C30.477 4.09334 26.3837 1.71199e-05 21.3343 1.64577e-05C16.285 1.57956e-05 12.1917 4.09334 12.1917 9.14271L12.1917 21.333Z" fill="#F8431C" />
    </svg>
  );
}
