const defaultState = {
  user: null,
  logoutBy: "none", // 何によってログアウトされたか（"user"(ログアウトボタンを押した) > "account_delete"(アカウントが削除された) < "permission"(リスナー、ファンクションで落ちた) > "none"（ログインしていない）)の順で優先される
  error: null,
};

const calcLogoutBy = (current, update) => {
  const accept = ["user", "account_delete", "permission", "none"];
  // console.log(current, update)

  // current, updateがacceptにあることを保証
  if (!accept.some(x => x === current) && !accept.some(x => x === update)) return "none";
  if (!accept.some(x => x === current)) return update;
  if (!accept.some(x => x === update)) return current;

  // acceptでのindexが小さいほうを返す
  const currentIndex = accept.indexOf(current);
  const updateIndex = accept.indexOf(update);
  return accept[Math.min(currentIndex, updateIndex)];
}

const user = (state = defaultState, action) => {
  let logoutBy;
  switch (action.type) {
    case 'AUTHORIZED' :
      return {
        ...state,
        user: action.payload.user,
        logoutBy: "none",
        error: null,
      };
    case 'AUTHORIZATION_FAILED' :
      return {
        ...state,
        error: 'authorization failed'
      };
    case 'NO_ROLE':
      return {
        ...state,
        error: 'no role'
      };
    case 'FAILED':
      return {
        ...state,
        error: action.error
      };
    case 'UNAUTHORIZED':
      logoutBy = calcLogoutBy(state.logoutBy, 'user');
      return {
        ...defaultState,
        logoutBy: logoutBy,
        error: null,
      };
    case 'ACCOUNT_DELETED':
      logoutBy = calcLogoutBy(state.logoutBy, 'account_delete');
      return {
        ...defaultState,
        logoutBy: logoutBy,
        error: logoutBy === 'account_delete' ? 'account deleted' : state.error,
      }
    case 'UNAUTHORIZED_USER':
      logoutBy = calcLogoutBy(state.logoutBy, 'permission');
      return {
        ...defaultState,
        logoutBy: logoutBy,
        error: logoutBy === 'permission' ? 'unauthorized user' : state.error,
      }
    default:
      return state;
  }
};

export default user;
