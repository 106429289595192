import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { logout } from './actions/login';
import { connect } from "react-redux";
import Logo from './svg/Logo';

const styles = {
  appBar: {
    width: '100vw',
    minWidth: '1024px',
    height: '32px',
    padding: '12px 0',
    background: '#FFFFFF',
    borderRadius: '0 0 16px 16px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftSide: {
    display: 'flex',
    height: '32px',
    marginLeft: '24px',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  rightSide: {
    display: 'flex',
    height: '32px',
    marginRight: '24px',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  leftBox: {
    textAlign: 'center',
  },
  rightBox: {
//    margin: '8px 12px',
    textAlign: 'center',
  },
  textLogo: {
    verticalAlign: 'top',
    marginLeft: '6px',
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    //lineHeight: '28px',
    color: '#444444'
  },
  secom: {
    verticalAlign: 'top',
    marginLeft: '10px',
    fontFamily: 'DIN Condensed',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#444444'
  },
  idArea: {
    height: '24px',
    marginLeft: '24px',
    paddingLeft: '15px',
    paddingRight: '16px',
    background: '#EEEEEE',
    borderRadius: '12px'
  },
  idText: {
    fontFamily: 'DIN condensed',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#444444'
  },
  displayNameText: {
    marginLeft: '9px',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#444444'
  },
  logout: {
    cursor: 'pointer',
    marginTop: '2px',
  },
  logoutTitle: {
    marginLeft: '4px',
    verticalAlign: 'top',
    fontFamily: 'DIN condensed',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#444444'
  },
  logo: {
    width: '32px',
    height: '32px'
  },
  icon: {
    marginLeft: '10px',
  },
};

class Topbar extends Component {

  onLogout = (e) => {
    e.preventDefault();
    if (!this.props.user) return;
    logout(this.props.dispatch, this.props.user);
  }

  render() {
    //console.log(JSON.stringify(this.props));
    const user = this.props.user || {};
    const clz = this.props.classes;
    return (
      <div className={clz.appBar}>
        <div className={clz.leftSide}>
          <div className={clz.leftBox}><Logo className={clz.logo} /></div>
          <div className={clz.leftBox + ' ' + clz.textLogo}>vguard</div>
          <img className={clz.icon} src="/powered.png" alt="SECOM" />
          <div className={clz.leftBox + ' ' + clz.idArea}>
            <span className={clz.idText}>ID: {user.uid}</span>
            <span className={clz.displayNameText}>{user.displayName}</span>
          </div>
        </div>
        <div className={clz.rightSide}>
          <div className={clz.logout} onClick={this.onLogout}>
            <img src="/logout.png" alt="Log out" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(
  connect(state => ({
    user: state.user && state.user.user,
    error: state.companyAccounts && state.companyAccounts.error
  }))(Topbar)
));
