import React from 'react';

export default function Search(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16" />
      <path d="M23.705 22.29L18.875 17.46C20.545 15.12 20.335 11.85 18.235 9.75C17.065 8.59 15.535 8 13.995 8C12.455 8 10.925 8.59 9.755 9.76C7.415 12.1 7.415 15.9 9.755 18.25C10.925 19.41 12.455 20 13.995 20C15.215 20 16.425 19.62 17.455 18.88L22.285 23.71C22.675 24.1 23.315 24.1 23.695 23.71C24.095 23.32 24.095 22.68 23.705 22.29ZM11.165 16.83C9.605 15.27 9.605 12.73 11.165 11.17C11.925 10.42 12.925 10 13.995 10C15.065 10 16.065 10.42 16.825 11.17C17.575 11.93 17.995 12.93 17.995 14C17.995 15.07 17.575 16.07 16.825 16.83C16.065 17.58 15.065 18 13.995 18C12.925 18 11.925 17.58 11.165 16.83Z" fill="#AAAAAA" />
    </svg>
  );
}
