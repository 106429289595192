import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appRemoveError } from './actions/appAction';
import { withStyles } from '@material-ui/core';
import ModalWrapper from './ModalWrapper';

// ダイアログエラー
const dialogStyles = {
  wrapperError: {
    textAlign: 'center',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    placeContent: 'center',
    padding: '209px 0px',
  },
}

class Dialog extends Component {
  onClose = (e) => {
    e.preventDefault();
    if (this.props.onClose) {
      this.props.onClose(e);
    }
    appRemoveError(this.props.dispatch);
  }

  render(){
    const classes = this.props.classes;
    return(
      <ModalWrapper
        open={!!this.props.dialogError}
        onClose={this.onClose}
        error={true}
        content={
          <p className={classes.wrapperError}>{this.props.dialogError}</p>
        }/>
    )
  }
}

export const ErrorDialog = withStyles(dialogStyles)(
  connect(state => ({
    dialogError: state.appState && state.appState.dialogError,
  }))(Dialog)
);


// インラインエラー
const inlineStyles = {
  inlineError: {
    color: 'red',
    textAlign: 'center',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    marginTop: '8px',
    marginBottom: '8px',
  },
}

class Inline extends Component {
  render(){
    const { classes, style } = this.props;
    return(
      <React.Fragment>
        {this.props.inlineError &&
          <div className={classes.inlineError} style={style} >
            {this.props.inlineError}
          </div>
        }
      </React.Fragment>
    )
  }
}

export const InlineError = withStyles(inlineStyles)(
  connect(state => ({
    inlineError: state.appState && state.appState.inlineError,
  }))(Inline)
);
