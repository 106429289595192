import { combineReducers } from 'redux';
import appState from './appReducer';
import user from './user';
import listeners from './listeners';
import companies from './companies';
import admin from './admin';
import companyAccounts from './companyAccounts';

const resetStateWithLogout = (state, action) => ({
  appState: appState(undefined, action),
  user: { ...state.user }, // userは使うので残す
  listeners: listeners(undefined, action),
  companies: companies(undefined, action),
  admin: admin(undefined, action),
  companyAccounts: companyAccounts(undefined, action),
})

const combinedReducers = combineReducers({
  appState: appState,
  user: user,
  listeners: listeners,
  companies: companies,
  admin: admin,
  companyAccounts: companyAccounts,
});

const rootReducer = (state, action) => {
  if (action.type === "CLEAR_STATE_WITH_LOGOUT") {
    state = resetStateWithLogout(state, action);
  }
  return combinedReducers(state, action);
}

export default rootReducer;
